import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  constructor(private http: HttpClientService) {}

  fetchProgramNames() {
    return this.http.get(`${APICONSTANTS.PROGRAM}/names`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  fetchProgram(queryParams = {}) {
    return this.http.get(`${APICONSTANTS.PROGRAM}`, queryParams).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  fetchProgramById(programId: number, queryParams = {}) {
    return this.http
      .get(`${APICONSTANTS.PROGRAM}/${programId}`, queryParams)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchProgramMetricsById(programId: number) {
    return this.http.get(`${APICONSTANTS.PROGRAM}/metrics/${programId}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveProgram(program: any) {
    return this.http.post(`${APICONSTANTS.PROGRAM}`, program).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  deleteProgram(programId: number) {
    return this.http.remove(`${APICONSTANTS.PROGRAM}/${programId}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateProgram(program: any) {
    return this.http.put(`${APICONSTANTS.PROGRAM}`, program).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  deleteProject(projectId: number) {
    return this.http.remove(`${APICONSTANTS.PROJECT}/${projectId}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchProgramContacts(programId: number) {
    return this.http.get(`${APICONSTANTS.PROGRAM}/${programId}/contacts`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveProgramContacts(programId: number, body: any) {
    return this.http
      .put(`${APICONSTANTS.PROGRAM}/${programId}/contacts`, body)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchDefaultContactGroup(programId: number) {
    return this.http
      .get(`${APICONSTANTS.PROGRAM}/${programId}/contacts/default-groups`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  exportProgram(programId: number) {
    return this.http.get(`${APICONSTANTS.PROGRAM}/${programId}/export`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveClosingAdminFeeData(data: any) {
    return this.http
      .post(
        `${APICONSTANTS.PROGRAM}/${data.programId}/closing-admin-fees}`,
        data
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  updateClosingAdminFeeData(data: any) {
    return this.http
      .put(
        `${APICONSTANTS.PROGRAM}/${data.programId}/closing-admin-fees}`,
        data
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchStates() {
    return this.http.get(`${APICONSTANTS.STATE_DATABASE}/names`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
