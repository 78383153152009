import { ActionReducerMap, createSelector } from '@ngrx/store';
import * as fromAuth from './auth/reducers/auth-reducer';
import * as fromPhase from './phases/reducers/phase-reducer';
import * as global from './global/reducers/global-reducer';

export interface RootReducerState {
  auth: fromAuth.AuthReducerState;
  phases: fromPhase.PhaseReducerState;
  global: global.GlobalReducerState;
}

export const rootReducer: ActionReducerMap<RootReducerState> = {
  auth: fromAuth.AuthReducer,
  phases: fromPhase.PhaseReducer,
  global: global.GlobalReducer,
};

export const getAuthState = (state: RootReducerState) => state.auth;
export const getPhasesState = (state: RootReducerState) => state.phases;
export const getGlobalState = (state: RootReducerState) => state.global;
export const getSelectedProject = (state: RootReducerState) =>
  state.global.selectedProject;

// defining selector in root-reducer to list Auth state
export const getIsLoggedInStatus = createSelector(
  getAuthState,
  fromAuth.getIsLoggedInStatus
);

export const getAuthUser = createSelector(getAuthState, fromAuth.getAuthUser);

export const getPhases = createSelector(getPhasesState, fromPhase.getPhases);

export const getUserList = createSelector(getGlobalState, global.userList);

export const getTransactionManagerList = createSelector(
  getGlobalState,
  global.transactionManagerList
);

export const getMarketLeaderList = createSelector(
  getGlobalState,
  global.marketLeaderList
);

export const getPropertyTypeList = createSelector(
  getGlobalState,
  global.propertyTypeList
);
