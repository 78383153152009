import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { PaceEquityUtils } from 'src/app/utils';

import { ExcelTemplate } from '../../constants/excel.constant';
import {
  HardCostMeasures,
  SoftCostMeasures,
} from 'src/app/models/model.scopeOfWork';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const EXCEL_EXTENSION = '.xlsx';
const Excel = require('exceljs');

@Injectable({
  providedIn: 'root',
})
export class ScopeOfWorkExcelService {
  USEFUL_LIFE_INDEX = 1;
  ELIGIBLE_SPEND_INDEX = 3;
  PERCENTAGE_USE_INDEX = 5;
  PACE_SCOPE_INDEX = 7;

  constructor(
    private paceEquityUtils: PaceEquityUtils,
    private datePipe: DatePipe
  ) {}

  getExcelFile(body: any, callback?: any) {
    // Create workbook and worksheet
    const workbook = new Excel.Workbook();

    this.constructExcelReport(workbook, body, callback);
    callback && callback();
    // Generate Excel File with given name
    const fileName = 'Scope of Work';
    this.exportWorkbook(workbook, fileName + '_export_' + new Date().getTime());
  }

  constructExcelReport(workbook: any, body: any, callback?: any) {
    let worksheet = workbook.addWorksheet('Scope of Work');

    const title = 'PACE Scope of Work';

    const titleRow = worksheet.addRow([title]);
    worksheet.mergeCells(`A${titleRow.number}:F${titleRow.number}`);
    titleRow.font = { bold: true, size: 16 };

    const projectNameRow = worksheet.addRow([
      this.paceEquityUtils.getProjectCodeName(body.project),
    ]);
    worksheet.mergeCells(`A${projectNameRow.number}:F${projectNameRow.number}`);
    projectNameRow.alignment = { horizontal: 'right' };
    projectNameRow.font = { size: 11 };

    const subTitleRow = worksheet.addRow([
      'Execution time : ' + this.datePipe.transform(new Date(), 'medium'),
    ]);
    worksheet.mergeCells(`A${subTitleRow.number}:F${subTitleRow.number}`);

    subTitleRow.alignment = { horizontal: 'right' };
    subTitleRow.font = { size: 11 };

    worksheet.mergeCells(`A4:M4`);

    // width of column
    const GCellColumn = worksheet.getColumn(7); // 7 corresponds to column G
    GCellColumn.width = 22;

    const HCellColumn = worksheet.getColumn(8); // 8 corresponds to column H
    HCellColumn.width = 3;

    const ICellColumn = worksheet.getColumn(9); // 9 corresponds to column I
    ICellColumn.width = 22;

    const JCellColumn = worksheet.getColumn(10); // 9 corresponds to column J
    JCellColumn.width = 3;

    const KCellColumn = worksheet.getColumn(11); // 10 corresponds to column K
    KCellColumn.width = 10;

    const LCellColumn = worksheet.getColumn(12); // 12 corresponds to column L
    LCellColumn.width = 3;

    const MCellColumn = worksheet.getColumn(13); // 13 corresponds to column M
    MCellColumn.width = 22;

    // header row
    const header = [
      'Measure',
      'Useful Life',
      null,
      'Eligible Spend',
      null,
      '%Use',
      null,
      'PACE Scope',
    ];
    this.constructExcel(worksheet, header, true, 'header');

    body.scopeOfWork.hardCostMeasures.forEach((data: HardCostMeasures) => {
      const measure = data.measure !== null ? data.measure : '-';
      const usefulLife = this.parseUsefulLife(data);
      const eligibleSpend =
        data.eligibleSpend !== null
          ? Number(data.eligibleSpend.toFixed(2))
          : '-';
      const percentageUse =
        data.percentageUse !== null ? data.percentageUse : '-';
      const paceScope =
        data.paceScope !== null ? Number(data.paceScope.toFixed(2)) : '-';

      const hardCostMeasure = [
        measure,
        usefulLife,
        null,
        eligibleSpend,
        null,
        percentageUse,
        null,
        paceScope,
      ];

      this.constructExcel(worksheet, hardCostMeasure);
    });

    const secondBlankRow = worksheet.addRow();
    worksheet.mergeCells(`A${secondBlankRow.number}:M${secondBlankRow.number}`);
    // subTotalHardCost row

    const subTotalHardCost = [
      'Sub Total Hard Costs',
      null,
      null,
      body.scopeOfWork.subtotalHardCostEligibleSpend
        ? Number(body.scopeOfWork.subtotalHardCostEligibleSpend.toFixed(2))
        : '-',
      null,
      null,
      null,
      body.scopeOfWork.subtotalHardCostPaceScope
        ? Number(body.scopeOfWork.subtotalHardCostPaceScope.toFixed(2))
        : '-',
    ];

    // weightAverageLife row
    const weightAverageLife = [
      'Weight Average Life (yrs)',
      null,
      null,
      null,
      null,
      null,
      null,
      body.scopeOfWork.weightAverageLife
        ? Number(body.scopeOfWork.weightAverageLife.toFixed(2))
        : '-',
    ];

    const totalHardCostBudget = [
      'Total Hard Cost Budget',
      null,
      null,
      null,
      null,
      null,
      null,
      body.scopeOfWork.totalHardCostBudget
        ? Number(body.scopeOfWork.totalHardCostBudget.toFixed(2))
        : '-',
    ];

    // hardCostRatio row
    const hardCostRatio = [
      'Hard Cost Ratio',
      null,
      null,
      null,
      null,
      null,
      null,
      body.scopeOfWork.hardCostRatio
        ? Number(body.scopeOfWork.hardCostRatio.toFixed(2))
        : '-',
    ];

    // Add the different sections row
    this.constructExcel(worksheet, subTotalHardCost, true);
    this.constructExcel(
      worksheet,
      weightAverageLife,
      true,
      'weightAverageLife'
    );
    this.constructExcel(worksheet, totalHardCostBudget, true);
    this.constructExcel(worksheet, hardCostRatio, true, 'hardCostRatio');

    const thirdBlankRow = worksheet.addRow();
    worksheet.mergeCells(`A${thirdBlankRow.number}:M${thirdBlankRow.number}`);

    // eligibleSoft row
    const eligibleSoft = [
      'Eligible Soft Costs (total)',
      'Budget',
      null,
      'Eligible PACE Proportion',
    ];
    this.constructExcel(worksheet, eligibleSoft, true);

    body.scopeOfWork.softCostMeasures.forEach((data: SoftCostMeasures) => {
      const measure = data.measure ? data.measure : '-';

      const budget = data.budget ? Number(data.budget) : '-';
      const eligiblePaceProportion = data.eligiblePaceProportion
        ? Number(data.eligiblePaceProportion.toFixed(2))
        : '-';

      const softMeasureData = [measure, budget, null, eligiblePaceProportion];
      this.constructExcel(
        worksheet,
        softMeasureData,
        false,
        'softCostMeasures'
      );
    });

    // const forthBlankRow = worksheet.addRow();
    // worksheet.mergeCells(`A${forthBlankRow.number}:M${forthBlankRow.number}`);

    // subTotalSoftCosts row
    const subTotalSoftCosts = [
      'Sub Total Soft Costs',
      null,
      null,
      body.scopeOfWork.subtotalSoftCostEligibleSpend
        ? Number(body.scopeOfWork.subtotalSoftCostEligibleSpend.toFixed(2))
        : '-',
      null,
      body.scopeOfWork.subtotalSoftCostPercentageUse
        ? Number(body.scopeOfWork.subtotalSoftCostPercentageUse.toFixed(2))
        : '-',
      null,
      body.scopeOfWork.subtotalSoftCostPaceScope
        ? Number(body.scopeOfWork.subtotalSoftCostPaceScope.toFixed(2))
        : '-',
    ];

    this.constructExcel(worksheet, subTotalSoftCosts, true);

    const fifthBlankRow = worksheet.addRow();
    worksheet.mergeCells(`A${fifthBlankRow.number}:M${fifthBlankRow.number}`);

    // totalPACEScope row
    const totalPACEScope = [
      'Total PACE Scope',
      null,
      null,
      body.scopeOfWork.totalEligibleSpend
        ? Number(body.scopeOfWork.totalEligibleSpend.toFixed(2))
        : '-',
      null,
      null,
      null,
      body.scopeOfWork.totalPaceScope
        ? Number(body.scopeOfWork.totalPaceScope.toFixed(2))
        : '-',
    ];
    this.constructExcel(worksheet, totalPACEScope, true);

    callback && callback();
  }

  parseUsefulLife({
    usefulLife,
    isUsefulLifeOverridden,
  }: {
    usefulLife: number | null;
    isUsefulLifeOverridden: boolean;
  }) {
    const usefulLifeNumber = usefulLife != null ? Math.round(usefulLife) : 0;
    return isUsefulLifeOverridden
      ? `${Math.round(usefulLifeNumber)}*`
      : usefulLifeNumber;
  }

  constructExcel(
    worksheet: any,
    rowData: any,
    isHeader = false,
    helperText = ''
  ) {
    const row = worksheet.addRow([rowData[0]]);

    if (isHeader) {
      worksheet.mergeCells(`A${row.number}:F${row.number}`);
      const cell = row.getCell(1);
      cell.font = { bold: true };
      if (helperText == 'header') this.setDarkBackground(cell);
    } else {
      worksheet.mergeCells(`A${row.number}:F${row.number}`);
    }

    for (let i = 1; i < rowData.length; i++) {
      const colNumber = 6 + i;
      const cell = row.getCell(colNumber);

      cell.value = rowData[i];

      if (isHeader) {
        cell.font = { bold: true };
        if (helperText == 'header') this.setDarkBackground(cell);
      } else {
        cell.alignment = { vertical: 'middle', horizontal: 'right' };
      }

      //Apply formats
      if (rowData[i] == '-' || helperText == 'weightAverageLife') continue;
      if (
        [
          this.USEFUL_LIFE_INDEX,
          this.ELIGIBLE_SPEND_INDEX,
          this.PACE_SCOPE_INDEX,
          this.PERCENTAGE_USE_INDEX,
        ].includes(i)
      ) {
        if (helperText == 'softCostMeasures') {
          if ([this.USEFUL_LIFE_INDEX, this.ELIGIBLE_SPEND_INDEX].includes(i))
            cell.numFmt = ExcelTemplate.CURRENCY;
        } else {
          if ([this.ELIGIBLE_SPEND_INDEX, this.PACE_SCOPE_INDEX].includes(i)) {
            cell.numFmt = ExcelTemplate.CURRENCY;
          } else if ([this.PERCENTAGE_USE_INDEX].includes(i)) {
            cell.numFmt = ExcelTemplate.RATE;
          }
          if (helperText == 'hardCostRatio') cell.numFmt = ExcelTemplate.RATE;
        }
      }
    }
  }

  setDarkBackground(cell: any) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '000000' },
    };
    cell.font = { ...cell.font, color: { argb: 'FFFFFFFF' } };
  }

  exportWorkbook(workbook: any, fileName: any) {
    workbook.xlsx
      .writeBuffer()
      .then((writeData: any) =>
        FileSaver.saveAs(
          new Blob([writeData], { type: EXCEL_TYPE }),
          fileName + EXCEL_EXTENSION
        )
      );
  }
}
