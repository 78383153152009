import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private http: HttpClientService) {}

  downloadFileStream(params: any) {
    return this.http.documentGet(`${APICONSTANTS.DOCUMENT_DOWNLOAD}`, {
      fileUrl: params,
    });
  }
}
