const COLUMN_TYPES = {
  CURRENCY: 'CURRENCY',
  YES_NO: 'YES_NO',
  Y_N: 'Y_N',
  DATE: 'DATE',
  RATE: 'RATE',
  NUMBER: 'NUMBER',
  LONG_TEXT: 'LONG_TEXT',
  EMPTY: 'EMPTY',
  HEADER: 'HEADER',
  CUSTOM: 'CUSTOM',
};

export const DATA_TAPE_CONSTANTS = {
  COLUMN_TYPES,
  HEADERS: [
    {
      headerData: 'Program Information',
      description: '',
      style: 'font-weight-bold',
      key: 'programInformation',
      type: COLUMN_TYPES.HEADER,
    },
    {
      headerData: 'Unique Identifier',
      description: 'Unique Loan #',
      style: '',
      key: 'programInformation_projectCode',
    },
    {
      headerData: 'Property Name',
      description: 'Property Name',
      style: '',
      key: 'programInformation_projectName',
    },
    {
      headerData: 'Municipality',
      description: 'Name of Municipality Participating in PACE Program',
      style: '',
      key: 'programInformation_propertyAddressCity',
    },
    {
      headerData: 'County',
      description: 'Name of County Participating in PACE Program',
      style: '',
      key: 'programInformation_propertyCounty',
    },
    {
      headerData: 'State',
      description: 'Two digit abbreviation of State where project is located',
      style: '',
      key: 'programInformation_propertyAddressState',
    },
    {
      headerData: 'PACE Program Name',
      description:
        'Name of the PACE Program under which the PACE assessment was originated',
      style: '',
      key: 'programInformation_programCodeName',
    },
    {
      headerData: 'PACE Program Administrator',
      description:
        'Entity that facilitates/coordinates  PACE transactions with third parties',
      style: '',
      key: 'programInformation_paceProgramAdministrator',
    },
    {
      headerData: 'Bond? (Y/N)',
      description: '',
      style: '',
      key: 'programInformation_bond',
      type: COLUMN_TYPES.Y_N,
    },
    {
      headerData: 'PACE Bond Issuer (if applicable)',
      description:
        'Public entity that issues PACE (and other) Special Revenue Bonds (Input N/A if not a Bond)',
      style: '',
      key: 'programInformation_bondIssuer',
    },
    {
      headerData: 'Assessment Billing Agent',
      description:
        'Either the name of the taxing authority or other administrator acting as servicer  for billing',
      style: '',
      key: 'programInformation_assessmentBillingAgent',
    },
    {
      headerData: 'Assessment Collections Agent',
      description:
        'Either the name of the taxing authority or other administrator responsible for collecting PACE assessments',
      style: '',
      key: 'programInformation_assessmentCollectionsAgent',
    },
    {
      headerData: 'Taxing Authority Name',
      description: 'Taxing Authority Name',
      style: '',
      key: 'programInformation_authority',
    },
    {
      headerData: 'Taxing Authority Credit Rating',
      description: 'Long Term Credit rating of County issued by an NRSRO',
      style: '',
      key: 'programInformation_taxingAuthorityCreditRating',
    },
    {
      headerData: 'Intermediate Collections Agent',
      description:
        'If applicable, name of entity collecting assessment payments from Collections Agent',
      style: '',
      key: 'programInformation_intermediateCollectionsAgent',
    },
    {
      headerData: 'Assessment Payee',
      description:
        'If applicable, name of entity collecting assessment payments from Intermediate Collections Agent',
      style: '',
      key: 'programInformation_paceBondTrustee',
    },
    {
      headerData: 'Payment Dates to Assessment Owner (Secured payment Date)',
      description:
        'The dates (Month and Day)  payments are due to the holder of assessment payment rights',
      style: '',
      key: 'programInformation_securedReceiptDates',
    },
    {
      headerData: 'Energy Savings Study Required? (Y/N)',
      description: '',
      style: '',
      key: 'programInformation_isEnergySavingsStudyRequired',
      type: COLUMN_TYPES.Y_N,
    },
    {
      headerData: 'Validity Opinion Obtained? (Y/N)',
      description: '',
      style: '',
      key: 'programInformation_isValidityOpinionObtained',
      type: COLUMN_TYPES.Y_N,
    },
    {
      headerData: 'Level of Validity Opinion',
      description: 'Program-level, Asset-level, or None',
      style: '',
      key: 'programInformation_validityOpinionLevel',
    },
    {
      headerData: '',
      description: '',
      style: '',
      key: '',
      type: COLUMN_TYPES.EMPTY,
    },
    {
      headerData: 'Financing Information',
      description: '',
      style: 'font-weight-bold',
      key: 'financingInformation',
      type: COLUMN_TYPES.HEADER,
    },
    {
      headerData: 'Financing Closing Date',
      description: 'Closing Date of PACE financing ',
      style: '',
      key: 'financingInformation_closeDate',
      type: COLUMN_TYPES.DATE,
    },
    {
      headerData: 'Financing Maturity Date',
      description: 'Final Payment Date of the PACE Assessment (MDY)',
      style: '',
      key: 'financingInformation_securedReceiptMaturityDate',
      type: COLUMN_TYPES.DATE,
    },
    {
      headerData: 'Original Assessment/Bond Balance',
      description:
        'Total amount of PACE financing funded or committed at closing',
      style: '',
      key: 'financingInformation_originalFace',
      type: COLUMN_TYPES.CURRENCY,
    },
    {
      headerData: 'Current Assessment/Bond Balance',
      description:
        'Outstanding amount of PACE financing as of the cut-off date of the data tape',
      style: '',
      key: 'financingInformation_currentBalance',
      type: COLUMN_TYPES.CURRENCY,
    },
    {
      headerData: 'Assessment/Bond Coupon',
      description:
        'Annual interest rate accruing on the Assessment (Net of Interest to the Program Administrator)',
      style: '',
      key: 'financingInformation_assetRate',
      type: COLUMN_TYPES.RATE,
    },
    {
      headerData: 'Original Term ',
      description: 'Term of the assessment, expressed in years',
      style: '',
      key: 'financingInformation_originalTermYrs',
    },
    {
      headerData: 'Payment Frequency',
      description:
        'Periodicity of the assessment payment (Annual, semi-annual, quarterly, etc)',
      style: '',
      key: 'financingInformation_paymentFrequency',
    },
    {
      headerData: 'Payment Status (current or past due)',
      description:
        'Indicate whether the PACE assessment is current on all payments due  or past due',
      style: '',
      key: 'financingInformation_paymentStatus',
    },
    {
      headerData: 'Next Payment Date',
      description:
        'Next date that an assessment/bond payment is due, as Month, Day, Yr (MDY)',
      style: '',
      key: 'financingInformation_nextPaymentDate',
      type: COLUMN_TYPES.DATE,
    },
    {
      headerData: 'Prepayment Penalty',
      description:
        'Details of prepayment, as a percent and time period (e.g., 3% through year 3, 2.5% through year 7, etc)',
      style: '',
      key: 'financingInformation_prepaymentTerms',
    },
    {
      headerData: 'Lender Consent Required? (Y/N)',
      description: '',
      style: '',
      key: 'financingInformation_isLenderConsentRequired',
      type: COLUMN_TYPES.Y_N,
    },
    {
      headerData: 'Lender Consent Obtained? (Y/N)',
      description: '',
      style: '',
      key: 'financingInformation_isLenderConsentObtained',
      type: COLUMN_TYPES.Y_N,
    },
    {
      headerData: 'Notice of PACE financing sent to lender? (Y or N/A)',
      description: 'Answer only if Lender Consent was not required or obtained',
      style: '',
      key: 'financingInformation_isNoticeOfPACEFinancingSentToLender',
      type: COLUMN_TYPES.CUSTOM,
    },
    {
      headerData: '',
      description: '',
      style: '',
      key: '',
      type: COLUMN_TYPES.HEADER,
    },
    {
      headerData: 'Project and Property Information',
      description: '',
      style: 'font-weight-bold',
      key: 'projectAndPropertyInformation',
      type: COLUMN_TYPES.HEADER,
    },
    {
      headerData: 'Project Type',
      description: 'Retrofit, Refinance, Major Rehab, New Construction',
      style: '',
      key: 'projectAndPropertyInformation_projectType',
    },
    {
      headerData: 'Project Status',
      description: 'Completed, In Progress, Not Yet Started',
      style: '',
      key: 'projectAndPropertyInformation_projectStatus',
    },
    {
      headerData: 'Project Estimated Completion Date',
      description: 'Expected Completion Date (MDY)',
      style: '',
      key: 'projectAndPropertyInformation_currentFinishDate',
      type: COLUMN_TYPES.DATE,
    },
    {
      headerData: 'Property Status',
      description:
        'Status of property: Existing, Gut Rehab, Ground-up Construction, Land, etc.)',
      style: '',
      key: 'projectAndPropertyInformation_propertyStatus',
    },
    {
      headerData: 'Improvement Type',
      description:
        'Energy Efficiency (EE), Renewable Energy (RE), Water Conservation (WC), Seismic (S) or other approved Improvement',
      style: 'h-200',
      key: 'projectAndPropertyInformation_disbursementProjectFundingScope',
    },
    {
      headerData: 'Property Type',
      description:
        'Category Description of Property: (e.g.,Industrial,  Lodging,  Multi-Family, Office, Retail, Assisted Living, Mixed Use, Special Use, Vacant, etc)',
      style: '',
      key: 'projectAndPropertyInformation_propertyType',
    },
    {
      headerData: 'Detailed Property Use',
      description:
        'Detailed Description of Property Type: (e.g., Company headquarters, medical office building, "lifestyle" retail/entertainment center, sheet metal manufacturer, etc.)',
      style: '',
      key: 'projectAndPropertyInformation_subVerticalPropertyType',
    },
    {
      headerData: 'Property Address',
      description: 'Address of the Property',
      style: '',
      key: 'projectAndPropertyInformation_propertyAddress',
    },
    {
      headerData: 'Property Zip Code',
      description: '5 digit zip code',
      style: '',
      key: 'projectAndPropertyInformation_propertyAddressZip',
    },
    {
      headerData: 'Occupancy Type ',
      description:
        'Tenancy type: (Owner-occupied, single tenant, multi-tenant, Anchor tenant )',
      style: '',
      key: 'projectAndPropertyInformation_occupancyType',
    },
    {
      headerData: 'Property Value (as-is)',
      description:
        'As-Is Value based on appraised or assessed value of the property (fill in, as appropriate)',
      style: '',
      key: 'projectAndPropertyInformation_asIsAppraisalValue',
      type: COLUMN_TYPES.CURRENCY,
    },
    {
      headerData: 'Property Value (as-complete)',
      description:
        'As-Complete Value based on appraised or assessed value of the property (fill in, as appropriate)',
      style: '',
      key: 'projectAndPropertyInformation_asCompletedAppraisedValue',
      type: COLUMN_TYPES.CURRENCY,
    },
    {
      headerData: 'Property Value (as-stabilized)',
      description:
        'As-Stabilized Value based on appraised or assessed value of the property (fill in, as appropriate)',
      style: '',
      key: 'projectAndPropertyInformation_asStabilizedAppraisalValue',
      type: COLUMN_TYPES.CURRENCY,
    },
    {
      headerData: 'Property Valuation Source',
      description:
        'Source of the Valuation: e.g., Appraisal, desktop appraisal, assessed value, etc.',
      style: '',
      key: 'projectAndPropertyInformation_appraisalFirm',
    },
    {
      headerData: 'Valuation Date',
      description: 'Date of the Valuation ',
      style: '',
      key: 'projectAndPropertyInformation_asIsAppraisalValueEffectiveDate',
      type: COLUMN_TYPES.DATE,
    },
    {
      headerData: 'Estimated Annual Energy Savings',
      description: '$ of average annual energy savings',
      style: '',
      key: 'projectAndPropertyInformation_estAnnualSavings',
      type: COLUMN_TYPES.CURRENCY,
    },
    {
      headerData: 'Savings to Investment Ratio',
      description: 'Project SIR ratio %',
      style: '',
      key: 'projectAndPropertyInformation_savingsToInvestmentRatio',
      type: COLUMN_TYPES.CUSTOM,
    },
    {
      headerData: 'Type of Environmental Report Received',
      description: 'Environmental Database Report, Phase I, Phase 2, etc.',
      style: '',
      key: 'projectAndPropertyInformation_environmentalReportType',
      type: COLUMN_TYPES.LONG_TEXT,
    },
    {
      headerData: 'Outstanding Environmental Issues',
      description:
        'Indicate any unresolved environmental issues as identified in the environmental report',
      style: '',
      key: 'projectAndPropertyInformation_environmentalStatusConclusion',
      type: COLUMN_TYPES.LONG_TEXT,
    },
    {
      headerData: '',
      description: '',
      style: '',
      key: '',
      type: COLUMN_TYPES.HEADER,
    },
    {
      headerData: 'Payment  & Mortgage Information',
      description: '',
      style: 'font-weight-bold',
      key: 'paymentAndMortgageInformation',
      type: COLUMN_TYPES.HEADER,
    },
    {
      headerData: 'Annual PACE Payment Amount',
      description:
        'Calculation of the annual average payment assessed pursuant to the financing agreement',
      style: '',
      key: 'paymentAndMortgageInformation_assessmentAnnualTotal',
      type: COLUMN_TYPES.CURRENCY,
    },
    {
      headerData: 'Other Annual Property taxes / special assessments',
      description:
        '$ amount of annual payments for property taxes and other special assessments',
      style: '',
      key: 'paymentAndMortgageInformation_otherAnnualPropertyTaxes',
      type: COLUMN_TYPES.CURRENCY,
    },
    {
      headerData: 'First PACE Assessment/Bond Payment Date',
      description:
        'If annual or semi-annual payments due from borrower, month and day of first payment in the tax year',
      style: '',
      key: 'paymentAndMortgageInformation_firstTaxPaymentDate',
    },
    {
      headerData: 'First PACE Assessment/Bond Payment Date (Principal)',
      description:
        'If annual or semi-annual payments due from borrower, month and day of first payment in the tax year',
      style: '',
      key: 'paymentAndMortgageInformation_firstTaxPrincipalPaymentDate',
    },
    {
      headerData: 'Second PACE Assessment/Bond Payment Date',
      description:
        'If semi-annual payments due from borrower, month and day of second payment in the tax year, otherwise N/A',
      style: '',
      key: 'paymentAndMortgageInformation_secondTaxPaymentDate',
    },
    {
      headerData: 'Other Payment Dates',
      description:
        'If not annual or semi-annual payments due from borrower, indicate which months payments are due',
      style: '',
      key: 'paymentAndMortgageInformation_otherPaymentDates',
    },
    {
      headerData: 'Other PACE Liens',
      description:
        '$ amount of other PACE liens on the property not included in this data tape',
      style: '',
      key: 'paymentAndMortgageInformation_otherPACELiens',
      type: COLUMN_TYPES.CURRENCY,
    },
    {
      headerData: 'PACE Lien to Value Ratio',
      description:
        'Percentage calculated as current outstanding PACE lien divided by Property Value',
      style: '',
      key: 'paymentAndMortgageInformation_asStabilizedPaceLienToValue',
      type: COLUMN_TYPES.RATE,
    },
    {
      headerData: 'Total PACE Lien to Value Ratio',
      description: 'All PACE liens as a percentage of Property Value',
      style: '',
      key: 'paymentAndMortgageInformation_asStabilizedTotalPaceLienToValue',
      type: COLUMN_TYPES.RATE,
    },
    {
      headerData: 'Mortgage Loan on Property? (Y/N)',
      description: '',
      style: '',
      key: 'paymentAndMortgageInformation_hasSeniorMortgageLender',
      type: COLUMN_TYPES.Y_N,
    },
    {
      headerData: 'Mortgage Origination Date',
      description: 'Date of commercial mortgage origination',
      style: '',
      key: 'paymentAndMortgageInformation_seniorMortgageCloseDate',
    },
    {
      headerData: 'Mortgage Interest Rate',
      description: 'Annual percentage rate',
      style: '',
      key: 'paymentAndMortgageInformation_seniorMortgageRate',
      type: COLUMN_TYPES.RATE,
    },
    {
      headerData: 'Original Mortgage Term',
      description: 'Expressed in Months',
      style: '',
      key: 'paymentAndMortgageInformation_seniorMortgageTermAndAmortizationTerm',
    },
    {
      headerData: 'Outstanding Mortgage Principal',
      description:
        'Outstanding balance of the  mortgage as of the most current date',
      style: '',
      key: 'paymentAndMortgageInformation_seniorDebtOutstandingBalance',
      type: COLUMN_TYPES.CURRENCY,
    },
    {
      headerData: 'Mortgage LTV',
      description:
        'Outstanding mortgage, plus any other senior/pari passu debt divided by Property Value',
      style: '',
      key: 'paymentAndMortgageInformation_asStabilizedMortgageLoanToValue',
      type: COLUMN_TYPES.RATE,
    },
    {
      headerData: 'Combined LTV',
      description:
        'Sum of PACE assessment, outstanding mortgage, plus any other senior/pari passu debt divided by Property Value',
      style: '',
      key: 'paymentAndMortgageInformation_asStabilizedCombinedLoanToValue',
      type: COLUMN_TYPES.RATE,
    },
    {
      headerData: 'Total Combined LTV',
      description: 'Same as CLTV, but with Total PACE Assessments ',
      style: '',
      key: 'paymentAndMortgageInformation_asStabilizedTotalCombinedLoanToValue',
      type: COLUMN_TYPES.RATE,
    },
    {
      headerData: 'Mortgage Debt Service $',
      description: 'Annual mortgage debt service payments',
      style: '',
      key: 'paymentAndMortgageInformation_mortgageDebtService',
      type: COLUMN_TYPES.CURRENCY,
    },
    {
      headerData: 'Property NOI (most recent)',
      description:
        'Annual net operating income of the property, ideally for the last three years, if available and as appropriate',
      style: '',
      key: 'paymentAndMortgageInformation_propertyNOI1',
      type: COLUMN_TYPES.CURRENCY,
    },
    {
      headerData: 'Property NOI (2nd most recent)',
      description:
        'Annual net operating income of the property, ideally for the last three years, if available and as appropriate',
      style: '',
      key: 'paymentAndMortgageInformation_propertyNOI2',
    },
    {
      headerData: 'Property NOI (3rd most recent)',
      description:
        'Annual net operating income of the property, ideally for the last three years, if available and as appropriate',
      style: '',
      key: 'paymentAndMortgageInformation_propertyNOI3',
    },
    {
      headerData: 'DSCR',
      description: 'Annual debt service coverage ratio, if available',
      style: '',
      key: 'paymentAndMortgageInformation_underWrittenDebtServiceCoverageRatio',
      type: COLUMN_TYPES.NUMBER,
    },
    {
      headerData: '',
      description: '',
      style: '',
      key: '',
      type: COLUMN_TYPES.HEADER,
    },
    {
      headerData:
        'Information required for Major Rehabs or Properties under New Construction:',
      description: '',
      style: 'font-weight-bold',
      key: 'majorRehabsOrNewConstructionPropertiesInformation',
      type: COLUMN_TYPES.HEADER,
    },
    {
      headerData: 'Construction Period Start Date',
      description: 'Date construction began or is estimated to begin (MDY)',
      style: '',
      key: 'majorRehabsOrNewConstructionPropertiesInformation_constructionPeriodStartDate',
      type: COLUMN_TYPES.DATE,
    },
    {
      headerData: 'Construction Period End Date',
      description: 'Date construction is estimated to be completed (MDY)',
      style: '',
      key: 'majorRehabsOrNewConstructionPropertiesInformation_constructionPeriodEndDate',
    },
    {
      headerData: 'PACE Proceeds Funded to Borrower to Date',
      description:
        'Dollar amount of total PACE proceeds disbursed as of the cut-off date',
      style: '',
      key: 'majorRehabsOrNewConstructionPropertiesInformation_disbursementsToDate',
      type: COLUMN_TYPES.CURRENCY,
    },
    {
      headerData: 'PACE Payment Escrow/ Reserve Amount',
      description:
        'Dollar amount reserves for PACE assessment payments during construction',
      style: '',
      key: 'majorRehabsOrNewConstructionPropertiesInformation_capitalizedInterest',
      type: COLUMN_TYPES.CURRENCY,
    },
    {
      headerData: 'PACE Payment Escrow/ Reserve Remaining Balance',
      description:
        'Dollar amount of reserves remaining  as of the cut-off date',
      style: '',
      key: 'majorRehabsOrNewConstructionPropertiesInformation_capitalizedInterestEndingBalance',
    },
    {
      headerData: 'Mortgage Lender',
      description: 'Name of Mortgage Lender',
      style: '',
      key: 'majorRehabsOrNewConstructionPropertiesInformation_mortgageLender',
    },
    {
      headerData: 'Sponsor/Developer',
      description: 'Name of Sponsor or Developer',
      style: '',
      key: 'majorRehabsOrNewConstructionPropertiesInformation_sponsor',
    },
    {
      headerData: 'Construction Company',
      description: 'Name of Construction Company',
      style: '',
      key: 'majorRehabsOrNewConstructionPropertiesInformation_constructionCompany',
    },
    {
      headerData: 'Title Company',
      description: 'Name of Title Company',
      style: '',
      key: 'majorRehabsOrNewConstructionPropertiesInformation_titleCompany',
    },
    {
      headerData: 'Escrow Agent',
      description: 'Name of Escrow Agent',
      style: '',
      key: 'majorRehabsOrNewConstructionPropertiesInformation_escrowAgent',
    },
    {
      headerData: 'Third Party Cost Consultant',
      description: 'Name of Third Party Cost Consultant',
      style: '',
      key: 'majorRehabsOrNewConstructionPropertiesInformation_thirdPartyCostConsultant',
    },
    {
      headerData: 'Construction Completion Guarantee? (Y/N)',
      description: 'If yes, provide details separately',
      style: '',
      key: 'majorRehabsOrNewConstructionPropertiesInformation_hasConstructionCompletionGuarantee',
      type: COLUMN_TYPES.Y_N,
    },
    {
      headerData:
        'PACE Assessment Payment Guarantee During Construction? (Y/N)',
      description: 'If yes, provide details separately',
      style: '',
      key: 'majorRehabsOrNewConstructionPropertiesInformation_hasGuaranteeDuringConstruction',
      type: COLUMN_TYPES.Y_N,
    },
    {
      headerData: 'Construction Delays or Cost Overruns? (Y/N)',
      description: 'If yes, provide details separately',
      style: '',
      key: 'majorRehabsOrNewConstructionPropertiesInformation_constructionDelays',
      type: COLUMN_TYPES.CUSTOM,
    },
    {
      headerData: '',
      description: '',
      style: '',
      key: '',
      type: COLUMN_TYPES.HEADER,
    },
    {
      headerData: 'For All Properties: Additional Information Requirements',
      description: '',
      style: 'font-weight-bold',
      key: 'additionalInformationRequirements',
      type: COLUMN_TYPES.HEADER,
    },
    {
      headerData: 'Appraisal',
      description: 'Provide Separately',
      style: '',
      key: 'additionalInformationRequirements_appraisal',
      type: COLUMN_TYPES.EMPTY,
    },
    {
      headerData: 'Environmental Report',
      description: 'Provide Separately',
      style: '',
      key: 'additionalInformationRequirements_environmentalReport',
      type: COLUMN_TYPES.EMPTY,
    },
    {
      headerData: 'Engineering Property Condition Report',
      description: 'Provide Separately',
      style: '',
      key: 'additionalInformationRequirements_engineeringPropertyConditionReport',
      type: COLUMN_TYPES.EMPTY,
    },
    {
      headerData: 'Credit/Underwriting Package',
      description: 'Provide Separately',
      style: '',
      key: 'additionalInformationRequirements_creditUnderwritingPackage',
      type: COLUMN_TYPES.EMPTY,
    },
  ],
};
