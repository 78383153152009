<div class="modal-header">
  <button type="button" class="btn-close" aria-label="Close" (click)="hideModal()"></button>
</div>
<div class="modal-body">
  <div class="text-center">
    <i class="fa fa-lock text-secondary fa-4x mb-3 animated rotateIn"></i>
    <h2 class="text-secondary">Change Password</h2>
  </div>

  <form [formGroup]="changePasswordForm">
    <div class="form-group">
      <label for="oldPassword">Old Password</label>
      <input
        type="password"
        class="form-control"
        formControlName="oldPassword"
        mdbInput
        name="oldPassword"
        [ngClass]="{
          'is-invalid':
            submitted && changePasswordForm.get('oldPassword')?.invalid
        }"
      />
    </div>
    <div class="form-group mt-2">
      <label for="password">New Password</label>
      <input
        type="password"
        class="form-control"
        formControlName="password"
        mdbInput
        name="password"
        [ngClass]="{
          'is-invalid': submitted && changePasswordForm.get('password')?.invalid
        }"
      />
    </div>
    <div class="form-group mt-2">
      <label for="confirmPassword">Confirm Password</label>
      <input
        type="password"
        class="form-control"
        formControlName="confirmPassword"
        mdbInput
        name="confirmPassword"
        [ngClass]="{
          'is-invalid':
            submitted && changePasswordForm.get('confirmPassword')?.invalid
        }"
      />
    </div>
  </form>
</div>
<div class="modal-footer d-flex justify-content-between p-0 rounded-0">
  <button
    type="button"
    mdbBtn
    class="btn z-depth-0 btn-light"
    color="link"
    outline="true"
    (click)="hideModal()"
  >
    Cancel
  </button>
  <button
    class="btn btn-secondary mb-0 border z-depth-0"
    type="submit"
    mdbBtn
    color="secondary"
    mdbWavesEffect
    (click)="changePassword()"
    [disabled]="buttonDisabled"
  >
    <span
      class="spinner-grow spinner-grow-sm"
      role="status"
      aria-hidden="true"
      *ngIf="buttonDisabled"
    ></span>
    <i class="icofont-paper-plane" *ngIf="!buttonDisabled"></i> Change Password
  </button>
</div>
