export const DASHBOARD_CLOSE_DATE = [
  { id: 1, value: 'This Month', label: 'This Month' },
  { id: 2, value: 'This Month-To-Date', label: 'This Month-To-Date' },
  { id: 3, value: 'This Quarter', label: 'This Quarter' },
  { id: 4, value: 'This Quarter-To-Date', label: 'This Quarter-To-Date' },
  { id: 5, value: 'This Year', label: 'This Year' },
  { id: 6, value: 'This Year-To-Date', label: 'This Year-To-Date' },
  { id: 7, value: 'Last Month', label: 'Last Month' },
  { id: 8, value: 'Last Quarter', label: 'Last Quarter' },
  { id: 9, value: 'Next Quarter', label: 'Next Quarter' },
  {
    id: 10,
    value: 'This Quarter And Next Quarter',
    label: 'This Quarter And Next Quarter',
  },
  { id: 11, value: 'Last Year', label: 'Last Year' },
  { id: 12, value: 'Next Year', label: 'Next Year' },

];
