import { MetaReducer } from '@ngrx/store';
import {
  PhaseReducer,
  PhaseReducerState,
} from './phases/reducers/phase-reducer';

import { hydrationMetaReducer } from './hydration.reducer';

export interface AppState {
  phase: PhaseReducerState;
}

export const appReducer = {
  phase: PhaseReducer,
};

export const metaReducers: MetaReducer[] = [hydrationMetaReducer];
