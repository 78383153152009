export class ColumnFilterData {
  eeStatus: any = null;
  rucaCode: any = null;
  cirrusInterest: any = null;
  cirrusStatus: any = null;
  buildingType: any = null;
  pricingScenario: any = null;
  isEsoteric: any = null;
  marketLeaderId: any = null;
}
