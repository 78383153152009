import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PricingAdjustmentDriversService {

  constructor(private http: HttpClientService) {}
  
  fetchPropertyTypes() {
    return this.http.get(`${APICONSTANTS.PRICING_ADJUSTMENT_DRIVERS}/property-types`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
