export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export class LoginRequestAction {
  readonly type = LOGIN_REQUEST_SUCCESS;
  constructor(public payload?: any) {}
}

export class LoginSuccessAction {
  readonly type = LOGIN_REQUEST;
}

export class LogoutAction {
  readonly type = LOGOUT_REQUEST;
}
