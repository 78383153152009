import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  constructor(private http: HttpClientService) {}

  fetchLenderData(queryParams = {}) {
    return this.http.get(`${APICONSTANTS.LENDER_DATABASE}`, queryParams).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchStateByValue(value: any, findBy = 'id') {
    return this.http
      .get(`${APICONSTANTS.STATE_DATABASE}/${value}`, { findBy })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateLender(data: any) {
    return this.http.put(`${APICONSTANTS.LENDER_DATABASE}`, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchLenderNames() {
    return this.http.get(`${APICONSTANTS.LENDER_DATABASE}/names`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchStateDatabase(queryParams = {}) {
    return this.http.get(`${APICONSTANTS.STATE_DATABASE}`, queryParams).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchSingleLender(lenderId: number) {
    return this.http.get(`${APICONSTANTS.LENDER_DATABASE}/${lenderId}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchLenderContacts(id: number) {
    return this.http.get(`${APICONSTANTS.LENDER_DATABASE}/${id}/contacts`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  exportLenderDatabase() {
    return this.http.get(`${APICONSTANTS.LENDER_DATABASE}/export`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchSelectedLenderContacts(lenderIds: string) {
    return this.http
      .get(`${APICONSTANTS.LENDER_DATABASE_CONTACTS}`, { lenderIds })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
