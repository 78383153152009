export const environment = {
  production: true,
  env: 'prod',
  baseUrl: 'https://api-qa-pace-equity.noveltytechnology.com',
  apiUrl: 'https://api-qa-pace-equity.noveltytechnology.com/api/proforma',
  versionCheckURL:
    'https://pace-versioning.s3.us-east-2.amazonaws.com/qa/version.json',
  paceEquityUrl: 'https://qa-pace-equity.noveltytechnology.com/',
  tinymceApiKey: '0qpmebao9fbljzs40m5xp7p2lwdz964c3w7c8nokg128zf6l',
  peBucketUrl: 'https://paceequitygallery.s3.us-east-2.amazonaws.com/dev/pe-commitment-images/'
};
