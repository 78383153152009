import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appPermissionAccess]',
})
export class PermissionAccessDirective {
  user: any;
  @Input('permission') permission: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.user = JSON.parse(localStorage.getItem('paceEquityUser') as any);
    if (this.user) {
      let accessPermission = this.user.permission;
      let permissionCode: any = [];
      accessPermission.map((x: any) => {
        let value = x.permission?.code;
        let accessType = x.accessType;
        let body = {
          value: value,
          accessType: accessType,
        };
        permissionCode.push(body);
      });
      this.permission = this.el.nativeElement.attributes.permission.value;
      let values = this.permission.split('-');
      let permissionData = permissionCode.find(
        (f: any) => f.value?.toUpperCase() == values[0] && f.accessType
      );
      if (permissionData) {
        if (values[1]) {
          if (permissionData.accessType == values[1]) {
            // if(values[2]){
            //   this.user.shareAccess.find(x=> x.)
            // }
          } else {
            if (this.el.nativeElement.type == 'checkbox') {
              __ngRendererSetElementAttributeHelper(
                this.renderer,
                this.el.nativeElement,
                'disabled',
                'true'
              );
            } else {
              this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
            }
          }
        }
      } else {
        this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
      }
    }
  }
}

type AnyDuringRendererMigration = any;

function __ngRendererSplitNamespaceHelper(name: AnyDuringRendererMigration) {
  if (name[0] === ':') {
    const match = name.match(/^:([^:]+):(.+)$/);
    return [match[1], match[2]];
  }
  return ['', name];
}

function __ngRendererSetElementAttributeHelper(
  renderer: AnyDuringRendererMigration,
  element: AnyDuringRendererMigration,
  namespaceAndName: AnyDuringRendererMigration,
  value?: AnyDuringRendererMigration
) {
  const [namespace, name] = __ngRendererSplitNamespaceHelper(namespaceAndName);
  if (value != null) {
    renderer.setAttribute(element, name, value, namespace);
  } else {
    renderer.removeAttribute(element, name, namespace);
  }
}
