import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { PaceEquityUtils } from 'src/app/utils';
import { ExcelTemplate } from '../../constants/excel.constant';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const EXCEL_EXTENSION = '.xlsx';
const Excel = require('exceljs');

@Injectable({
  providedIn: 'root',
})
export class ScopeSummaryExcelService {
  PACE_SCOPE_INDEX = 1;
  USEFUL_LIFE_INDEX = 2;
  ENERGY_SAVINGS_INDEX = 4;
  WATER_SAVINGS_INDEX = 5;
  OPERATIONAL_SAVINGS_INDEX = 6;
  TOTAL_INDEX = 7;

  constructor(
    private paceEquityUtils: PaceEquityUtils,
    private datePipe: DatePipe
  ) {}

  getExcelFile(body: any, callback?: any) {
    // Create workbook and worksheet
    const workbook = new Excel.Workbook();

    this.constructExcelReport(workbook, body, callback);
    callback && callback();
    // Generate Excel File with given name
    const fileName = 'Scope Summary';
    this.exportWorkbook(workbook, fileName + '_export_' + new Date().getTime());
  }

  constructExcelReport(workbook: any, body: any, callback?: any) {
    let worksheet = workbook.addWorksheet('Scope Summary');

    const title = 'PACE Scope of Work (Summary)';

    const titleRow = worksheet.addRow([title]);
    worksheet.mergeCells(`A${titleRow.number}:F${titleRow.number}`);
    titleRow.font = {
      bold: true,
      size: 16,
    };

    const projectNameRow = worksheet.addRow([
      this.paceEquityUtils.getProjectCodeName(body.project),
    ]);
    worksheet.mergeCells(`A${projectNameRow.number}:F${projectNameRow.number}`);
    projectNameRow.alignment = { horizontal: 'right' };
    projectNameRow.font = { size: 11 };

    const subTitleRow = worksheet.addRow([
      'Execution time : ' + this.datePipe.transform(new Date(), 'medium'),
    ]);
    worksheet.mergeCells(`A${subTitleRow.number}:F${subTitleRow.number}`);

    subTitleRow.alignment = { horizontal: 'right' };
    subTitleRow.font = { size: 11 };
    // blank row
    // worksheet.mergeCells(`A4:F4`);
    worksheet.mergeCells('A4:M4');

    worksheet.mergeCells('J5:M5');

    const cell = worksheet.getCell('J5');
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { bold: true };

    this.setDarkBackground(cell);

    cell.value = 'Annual Savings';

    // width of column

    const GCellColumn = worksheet.getColumn(7); // 7 corresponds to column G
    GCellColumn.width = 22;

    const HCellColumn = worksheet.getColumn(8); // 8 corresponds to column H
    HCellColumn.width = 22;

    const ICellColumn = worksheet.getColumn(9); // 8 corresponds to column I
    ICellColumn.width = 3;

    const JCellColumn = worksheet.getColumn(10); // 9 corresponds to column J
    JCellColumn.width = 22;

    const KCellColumn = worksheet.getColumn(11); // 9 corresponds to column K
    KCellColumn.width = 22;

    const LCellColumn = worksheet.getColumn(12); // 10 corresponds to column L
    LCellColumn.width = 22;

    const MCellColumn = worksheet.getColumn(13); // 12 corresponds to column M
    MCellColumn.width = 22;

    const NCellColumn = worksheet.getColumn(14); // 13 corresponds to column N
    NCellColumn.width = 22;

    const OCellColumn = worksheet.getColumn(15); // 14 corresponds to column O
    OCellColumn.width = 22;

    const PCellColumn = worksheet.getColumn(116); // 15 corresponds to column P
    PCellColumn.width = 22;

    const header = [
      null,
      'PACE Scope',
      'Useful Life',
      null,
      'Energy Savings',
      'Water Savings',
      'Operational Savings',
      'Total',
    ];

    this.constructExcel(worksheet, header, true, 'header');
    body.scopeSummary.scopeSummaryCategories.forEach((data: any) => {
      const name = data.name !== null ? data.name : '-';
      const paceScope =
        data.paceScope !== null ? Number(data.paceScope.toFixed(2)) : '-';
      const usefulLife =
        data.usefulLife !== null ? Number(data.usefulLife.toFixed(2)) : '-';
      const energySavings =
        data.energySavings !== null
          ? Number(data.energySavings.toFixed(2))
          : '-';
      const waterSavings =
        data.waterSavings !== null ? Number(data.waterSavings.toFixed(2)) : '-';
      const operationalSavings =
        data.operationalSavings !== null
          ? Number(data.operationalSavings.toFixed(2))
          : '-';
      const totalAnnualSavings =
        data.totalAnnualSavings !== null
          ? Number(data.totalAnnualSavings.toFixed(2))
          : '-';

      const scopeSummaryCategories = [
        name,
        paceScope,
        usefulLife,
        null,
        energySavings,
        waterSavings,
        operationalSavings,
        totalAnnualSavings,
      ];
      this.constructExcel(worksheet, scopeSummaryCategories);
    });

    // blank row
    const blankRow = worksheet.addRow();
    worksheet.mergeCells(`A${blankRow.number}:M${blankRow.number}`);

    const softCostRow = [
      'SoftCosts',
      body.scopeSummary.totalSoftCost ? body.scopeSummary.totalSoftCost : '-',
    ];

    this.constructExcel(worksheet, softCostRow);

    const totalRow = [
      'Total',
      body.scopeSummary.totalPaceScope
        ? Number(body.scopeSummary.totalPaceScope.toFixed(2))
        : '-',
      body.scopeSummary.totalUsefulLife
        ? Math.round(Number(body.scopeSummary.totalUsefulLife))
        : '-',
      null,
      body.scopeSummary.totalEnergySavings
        ? Number(body.scopeSummary.totalEnergySavings.toFixed(2))
        : '-',
      body.scopeSummary.totalWaterSavings
        ? Number(body.scopeSummary.totalWaterSavings.toFixed(2))
        : '-',
      body.scopeSummary.totalOperationalSavings
        ? Number(body.scopeSummary.totalOperationalSavings.toFixed(2))
        : '-',
      body.scopeSummary.totalAnnualSavings
        ? Number(body.scopeSummary.totalAnnualSavings.toFixed(2))
        : '-',
    ];

    this.constructExcel(worksheet, totalRow, true, 'total');
  }

  setDarkBackground(cell: any) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '000000' },
    };
    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
  }

  constructExcel(
    worksheet: any,
    rowData: any,
    isHeader = false,
    helperText = ''
  ) {
    const row = worksheet.addRow([rowData[0]]);

    if (isHeader) {
      worksheet.mergeCells(`A${row.number}:F${row.number}`);
      const cell = row.getCell(1);
      cell.font = { bold: true };
    } else {
      worksheet.mergeCells(`A${row.number}:F${row.number}`);
    }

    for (let i = 1; i < rowData.length; i++) {
      const colNumber = 6 + i;
      const cell = row.getCell(colNumber);
      cell.value = rowData[i];

      if (rowData[i] !== '-') {
        if (
          [
            this.PACE_SCOPE_INDEX,
            this.ENERGY_SAVINGS_INDEX,
            this.WATER_SAVINGS_INDEX,
            this.OPERATIONAL_SAVINGS_INDEX,
            this.TOTAL_INDEX,
          ].includes(i)
        ) {
          cell.numFmt = ExcelTemplate.CURRENCY;
        }
      }
      if (!isHeader || helperText == 'total')
        cell.alignment = { vertical: 'middle', horizontal: 'right' };
      if (helperText == 'header') this.setDarkBackground(cell);
    }
  }

  exportWorkbook(workbook: any, fileName: any) {
    workbook.xlsx
      .writeBuffer()
      .then((writeData: any) =>
        FileSaver.saveAs(
          new Blob([writeData], { type: EXCEL_TYPE }),
          fileName + EXCEL_EXTENSION
        )
      );
  }
}
