<!-- Sidenav -->

<ul id="scroll-container" class="sidenav-menu">
  <mdb-sidenav-item>
    <a
      class="sidenav-link"
      tabindex="0"
      [routerLink]="['/dashboard']"
      routerLinkActive="active"
      permission="DASHBOARD"
      appPermissionAccess
    >
      <i class="fas fa-th-large fa-fw me-3"></i><span>Dashboard</span>
    </a>
  </mdb-sidenav-item>
  <mdb-sidenav-item>
    <a
      class="sidenav-link"
      tabindex="0"
      [routerLink]="['/programs']"
      routerLinkActive="active"
      permission="PROGRAM"
      appPermissionAccess
    >
      <i class="fas fa-newspaper fa-fw me-3"></i><span>Programs</span>
    </a>
  </mdb-sidenav-item>
  <mdb-sidenav-item>
    <a
      class="sidenav-link"
      tabindex="0"
      [routerLink]="['/projects']"
      routerLinkActive="active"
      *ngIf="permissionData?.hasProject"
    >
      <i class="fas fa-briefcase fa-fw me-3"></i><span>Projects</span>
    </a>
  </mdb-sidenav-item>
  <mdb-sidenav-item>
    <a
      class="sidenav-link"
      tabindex="0"
      [routerLink]="['/contact-directory']"
      routerLinkActive="active"
      permission="CONTACT_DIRECTORY"
      appPermissionAccess
    >
      <i class="fas fa-address-book fa-fw me-3"></i><span>Contacts</span>
    </a>
  </mdb-sidenav-item>
  <mdb-sidenav-item>
    <a
      class="sidenav-link"
      tabindex="0"
      [routerLink]="['/state-database']"
      routerLinkActive="active"
      permission="STATE_DATABASE"
      appPermissionAccess
    >
      <i class="fas fa-fa-solid fa-flag-usa fa-fw me-3"></i
      ><span>State Database</span>
    </a>
  </mdb-sidenav-item>
  <mdb-sidenav-item>
    <a
      class="sidenav-link"
      tabindex="0"
      [routerLink]="['/cre-capital-database']"
      routerLinkActive="active"
      permission="LENDER_DATABASE"
      appPermissionAccess
    >
      <i class="fas fa-fa fa-university fa-fw me-3"></i
      ><span>CRE Capital Wiki</span>
    </a>
  </mdb-sidenav-item>
</ul>

<!-- Sidenav -->
