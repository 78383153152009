import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private assetSrc = new BehaviorSubject([]);
  updatedAsset = this.assetSrc.asObservable();

  private documentPathSrc = new BehaviorSubject([]);
  updatedDocumentPath = this.documentPathSrc.asObservable();

  private documentDownloadSrc = new BehaviorSubject([]);
  updatedDocumentDownload = this.documentDownloadSrc.asObservable();

  private documentUploadSrc = new BehaviorSubject([]);
  updatedDocumentUpload = this.documentUploadSrc.asObservable();

  private documentMoveSrc = new BehaviorSubject([]);
  updatedDocumentMove = this.documentMoveSrc.asObservable();

  private projectUpdateFlagObs = new BehaviorSubject<boolean>(false);
  projectUpdateFlag = this.projectUpdateFlagObs.asObservable();

  private updatePaymentSrc = new BehaviorSubject(false);
  paymentUpdateFlag = this.updatePaymentSrc.asObservable();

  private emitGlobalReportingFlag = new BehaviorSubject(false);
  generateReportFlag = this.emitGlobalReportingFlag.asObservable();

  private emitGlobalReportingLoading = new BehaviorSubject(false);
  globalReportingLoadingFlag = this.emitGlobalReportingLoading.asObservable();

  private emitLenderContactCount = new BehaviorSubject(0);
  lenderContactUpdate = this.emitLenderContactCount.asObservable();

  private upateScopeofWork = new BehaviorSubject(null);
  scopeOfWorkFlag = this.upateScopeofWork.asObservable();

  private updatePricingAdjustmentSubject = new BehaviorSubject(true);
  pricingAdjustmentFlag = this.updatePricingAdjustmentSubject.asObservable();

  private isUpdatePropertyType = new BehaviorSubject(false);
  updateAssetProperty = this.isUpdatePropertyType.asObservable();

  private refreshFetchProject = new BehaviorSubject(null);
  refreshProject = this.refreshFetchProject.asObservable();

  private updateProjectPandLSubject = new BehaviorSubject(true);
  projectPandLFlag = this.updateProjectPandLSubject.asObservable();

  constructor() {}

  addUpdatedAsset(asset: any) {
    this.assetSrc.next(asset);
  }

  emitPaymentUpdateFlag(flag: boolean) {
    this.updatePaymentSrc.next(flag);
  }

  setGloablReporintLoading(flag: boolean) {
    this.emitGlobalReportingLoading.next(flag);
  }

  generateGlobalReporting(data: any) {
    this.emitGlobalReportingFlag.next(data);
  }

  updateDocumentPath(document: any) {
    this.documentPathSrc.next(document);
  }

  emitProjectFlag(flag: any) {
    this.projectUpdateFlagObs.next(flag);
  }

  updateDocumentDownload(documentInfo: any) {
    this.documentDownloadSrc.next(documentInfo);
  }

  updateDocumentUpload(documentInfo: any) {
    this.documentUploadSrc.next(documentInfo);
  }

  updateDocumentMove(documentInfo: any) {
    this.documentMoveSrc.next(documentInfo);
  }

  updateLenderContactCount(count: any) {
    this.emitLenderContactCount.next(count);
  }

  updateScopeofWork(flag: any) {
    this.upateScopeofWork.next(flag);
  }

  updatePricingAdjustment(flag: boolean = true) {
    this.updatePricingAdjustmentSubject.next(flag);
  }

  updateProjectPandL(body: any) {
    this.updateProjectPandLSubject.next(body);
  }

  emitUpdatePropertyType(flag: boolean = false) {
    this.isUpdatePropertyType.next(flag);
  }

  emitFetchProject(data: any) {
    this.refreshFetchProject.next(data);
  }
}
