import { NgModule } from '@angular/core';

import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import * as guards from './guards';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/layout-secure.module').then(
        (m) => m.LayoutSecureModule
      ),
    canActivate: [guards.AuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/layout-public.module').then(
        (m) => m.LayoutPublicModule
      ),
  },
  { path: '**', redirectTo: '/not-found' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
