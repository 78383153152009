export const ExcelHeader = {
  CAPIREPORT: new Map()
    .set('bpcId', 'Investor ID')
    .set('taxPaymentDates', 'Tax Payment Date(s)')
    .set('securedPaymentDates', 'Secured Receipt Date(s)')
    .set('paceProjectName', 'PACE Project Name')
    .set('grossCouponRate', 'Gross Coupon Rate')
    .set('servicingFeeRate', 'Servicing Fee')
    .set('netCouponRate', 'Net Coupon Rate')
    .set('basis', 'Basis')
    .set('purchasedAccrualStartDate', 'Purchased Accrual Start Date')
    .set('purchasedAccrualEndDate', 'Purchased Accrual End Date')
    .set('fundedCapitalizedInterest', 'Funded Capitalized Interest')
    .set('distributedCapitalizedInterest', 'Distributed Capitalized Interest')
    .set('sweepDates', 'Sweep Date(s)')
    // .set('firstCapISweepDate', 'First Cap I Sweep Date')
    // .set('secondCapISweepDate', 'Second Cap I Sweep Date')
    // .set('thirdCapISweepDate', 'Third Cap I Sweep Date')
    // .set('fourthCapISweepDate', 'Fourth Cap I Sweep Date')
    // .set('fifthCapISweepDate', 'Fifth Cap I Sweep Date')
    .set('capIStartorLastSweepDate', 'Cap I Start or Last Sweep Date')
    .set('nextCapISweepDate', 'Next CapI Sweep Date')
    .set('paymentDueThisPeriod', 'Payment Due This Period?')
    .set('nextCapINetPayment', 'Next Cap I Net Payment')
    .set('nextCapIServicingFee', 'Next Cap I Servicing Fee')
    .set(
      'reservedCapitalizedInterestBalance',
      'Reserved Capitalized Interest Balance'
    )
    .set('netAccruedInterest', 'Net Accrued Interest (BPC)')
    .set('servicingFeeAccrued', 'Servicing Fee Accrued (PEQ)'),
  AMORTIZATIONREPORT: new Map()
    .set('bpcId', 'Investor ID')
    .set('taxPaymentDates', 'Tax Payment Date(s)')
    .set('securedPaymentDates', 'Secured Receipt Date(s)')
    .set('paceProjectName', 'PACE Project Name')
    .set('grossCouponRate', 'Gross Coupon Rate')
    .set('servicingFeeRate', 'Servicing Fee')
    .set('programAdminRate', 'Program Admin Rate')
    .set('netCouponRate', 'Net Coupon Rate')
    .set('originalFace', 'Original Face')
    .set('beginningPrincipalBalance', 'Beginning Principal Balance')
    .set('termYears', 'Term (Years)')
    .set('periodsPerYr', 'Payments Per Year')
    .set('amortizationStartDate', 'Amortization Start Date')
    .set('isAmortizing', 'Is Amortizing')
    .set('firstOrLastSecuredPaymentDate', 'First Or Last Secured Receipt Date')
    .set('nextSecuredPaymentDate', 'Next Secured Receipt Date')
    .set('paymentDueThisPeriod', 'Payment Due This Period?')
    .set('netAccruedInterest_RP', 'Net Accrued Interest(Reporting Period)')
    .set('servicingFeeAccrued_RP', 'Servicing Fee Accrued(Reporting Period)')
    .set('netAccruedInterest_PP', 'Net Accrued Interest(Payment Period)')
    .set('servicingFeeAccrued_PP', 'Servicing Fee Accrued(Payment Period)')
    .set('programAdminFee', 'Program Admin Fee')
    .set('expectedInterestPayment', 'Expected Interest Payment')
    .set('expectedPrincipalPayment', 'Expected Principal Payment')
    .set('totalPIExpected', 'Total PI Expected')
    .set('piPmt_AmoSched', 'Pi Pmt Amortization Schedule')
    .set('piPmt_Published', 'Pi Pmt Published')
    .set('piPmt_Variance', 'Pi Pmt Variance')
    .set('notes', 'Notes')
    .set('paymentReceivedDate', 'Collected Payment Date')
    .set('principalPaymentRecieved', 'Collected Principal Payment')
    .set('interestAmountReceived', 'Collected Interest Payment')
    .set('adminFeeReceived', 'Collected Program Admin Fee')
    .set('peServicingFeeReceived', 'Collected PEQ Admin Fee'),

  // .set('prepaidPrincipal','Collected Prepaid Principal')
  // .set('prepaidInterest','Collected Prepaid Interest')
  // .set('prepaymentFee','Collected Prepayment Fee'),
  CLIENT_ASSESSMENT_SCHEDULE: new Map()
    .set('clientAssessmentDueDate', 'Assessment Due Date')
    .set('pmt', 'PMT #')
    .set('beginBalance', 'Begin Balance')
    .set('interestPayment', 'Interest Payment')
    .set('principalPayment', 'Principal Payment')
    .set('programAdministrationFees', 'Program Administration Fees')
    .set('peServicing', 'PEQ Admin Fee')
    .set('totalPayment', 'Total Payment'),
  //   ASSETREPORT: new Map()
  //     .set('inputs', '')
  //     .set('value1', '')
  //     .set('value2', '')
  //     .set('empty1', '')
  //     .set('securedPaymentDate', 'Secured Payment Date')
  //     .set('pmt', 'Pmt #')
  //     .set('beginBalance', 'Begin Balance')
  //     .set('interestPayment', 'Interest Payment')
  //     .set('principalPayment', 'Principal Payment')
  //     .set('totalPIPayment', 'Total P&I Payment')
  //     .set('endingBalance', 'Ending Balance')
  //     .set('empty2', '')
  //     .set('pehInterest', 'PEH Interest')
  //     .set('peServicing', 'PACE Equity Servicing')
  //     .set('adminInterest', 'Admin Interest')
  //     .set('empty3', '')
  //     .set('assessmentDueDateClient', 'Assessment Due Date')
  //     .set('pmtClient', 'Pmt #')
  //     .set('beginBalanceClient', 'Begin Balance')
  //     .set('interestPaymentClient', 'Interest Payment')
  //     .set('principalPaymentClient', 'Principal Payment')
  //     .set('programAdministrationFeesClient', 'Program Administration Fees')
  //     .set('peServicingClient', 'PEQ Admin Fee')
  //     .set('totalPaymentClient', 'Total Payment')
  DATA_TAPE_REPORT: new Map()
    .set('headName', '')
    .set('projectName', 'PACE Project Name')
    .set('programName', 'PACE Program / Administrator')
    .set('securityForm', 'Security Form')
    .set('closeDate', 'Close Date')
    .set('originalFace', 'Original Face')
    .set('currentFace', 'Current Face')
    .set('statedAssessmentRate', 'Stated Assessment Rate')
    .set('grossCouponRate', 'Gross Coupon Rate')
    .set('netCouponRate', 'Net Coupon Rate')
    .set('servicingRate', 'Servicing Rate')
    .set('paymentsPerYear', 'Payments per Year')
    .set('purchasePrice', 'Purchase Price')
    .set('interestAccrualMethod', 'Interest Accrual Method')
    .set('originalTermYrs', 'Original Term (Years)')
    .set('prePaymentTerms', 'Prepayment Terms')
    .set('lockoutTermYrs', 'Lockout Term (Years)')
    .set('purchaseDate', 'Purchase Date')
    .set('capitalizedInterestStartDate', 'Capitalized Interest Start Date')
    .set('capitalizedInterestEndDate', 'Capitalized Interest End Date')
    .set('capitalizedInterestAmount', 'Capitalized Interest Amount')
    // .set('sweep1Date', 'First Cap I Sweep Date')
    // .set('sweep2Date', 'Second Cap I Sweep Date')
    // .set('sweep3Date', 'Third Cap I Sweep Date')
    // .set('sweep4Date', 'Fourth Cap I Sweep Date')
    // .set('sweep5Date', 'Fifth Cap I Sweep Date')
    .set('sweepDates', 'Cap I Sweep Date(s)')
    // .set('sweep1Amount', 'First Cap I Sweep Amount')
    // .set('sweep2Amount', 'Second Cap I Sweep Amount')
    // .set('sweep3Amount', 'Third Cap I Sweep Amount')
    // .set('sweep4Amount', 'Fourth Cap I Sweep Amount')
    // .set('sweep5Amount', 'Fifth Cap I Sweep Amount')
    .set('sweepAmounts', 'Cap I Sweep Amount(s)')
    .set('amortizationStartDate', 'Amortization Start Date')
    .set('firstTaxPaymentDate', 'First Tax Payment Date')
    .set('secondTaxPaymentDate', 'Second Tax Payment Date')
    .set('firstSecuredPaymentDate', 'First Secured Receipt Date')
    .set('secondSecuredPaymentDate', 'Second Secured Receipt Date')
    .set('finalMaturityDate', 'Final Maturity Date')
    .set('pIPaymentAnnualTotal', 'P & I Payment-Annual Total')
    .set('assessmentAnnualTotal', 'Assessment-Annual Total')
    .set('totalAggregatePayment', 'PACE Payments (Total Aggregate Payment)')
    .set('projectCost', 'PACE Project Cost (Direct Cost)')
    .set('propertyAddressStreet', 'Property Address - Street')
    .set('propertyAddressCity', 'Property Address - City')
    .set('propertyAddressState', 'Property Address - State')
    .set('propertyAddressZip', 'Property Address - Zip')
    .set('propertyCounty', 'Property County')
    .set('msa', 'MSA')
    .set('rucaCode', 'RUCA Code')
    .set('taxKeys', 'Tax Keys')
    .set('propertyType', 'Property Type')
    .set('subVerticalPropertyType', 'Sub-Vertical Property Type')
    .set('environmentalReportDate', 'Environment Report Date (phase I)')
    .set('numberOfUnits', 'Number of Units (1)')
    .set('unitOfMeasure', 'Unit of Measure')
    .set('netBuildingArea', 'Net Building Area (SF)')
    .set(
      'currentOccupancyStatus',
      'Current Occupancy Status (Occupied or Non-Occupied)'
    )
    .set('appraisalFirm', 'Appraisal Firm')
    .set(
      'asIsAppraisalValueEffectiveDate',
      'As-Is Appraisal Value Efective Date'
    )
    .set('asIsAppraisalValue', 'As-Is Appraised Value')
    .set(
      'asCompleteAppraisalValueEffectiveDate',
      'As-Complete Appraisal Value Effective Date'
    )
    .set('asCompletedApporaisedValue', 'As-Completed Appraisal Value')
    .set(
      'appraisalAnticipatedStabilizationDate',
      'Appraisal Anticipated Stabilization Date'
    )
    .set('asStabilizedAppraisalValue', 'As-Stabilized Appraised Value')
    .set('asCompletePaceLienToValue', 'As-Complete PACE Lien-to-Value')
    .set('asStabilizedPaceLienToValue', 'As-Stabilized PACE Lien-to-Value')
    .set('asCompleteMortgageLoanToValue', 'As-Complete Mortgage Loan-to-Value')
    .set(
      'asStabilizedMortgageLoanToValue',
      'As-Stabilized Mortgage Loan-to-Value'
    )
    .set('asCompleteCombinedLoanToValue', 'As-Complete Combined Loan-to-Value')
    .set(
      'asStabilizedCombinedLoanToValue',
      'As-Stabilized Combined Loan-to-Value'
    )
    .set('underWrittenNcf', 'Underwritten NCF')
    .set(
      'underWrittenDebtServiceCoverageRatio',
      'Underwritten Debt Service Coverage Ratio'
    )
    .set('seniorMortgageLender', 'Senior Mortgage Lender')
    .set('seniorDebtOutstandingBalance', 'Senior Debt Outstanding Balance')
    .set('seniorMortgageRate', 'Senior Mortgage Rate')
    .set('seniorMortgageTermYrs', 'Senior Mortgage Term (years)')
    .set('seniorMortgageAmortizationTerm', 'Senior Mortgage Amortization Term')
    .set('seniorMortgageMaturityDate', 'Senior Mortgage Maturity Date')
    .set(
      'currentTotalDebtBalanceOutstanding',
      'Current Total Debt Balance Outstanding'
    )
    .set('paceAsPercentOfCapitalStack', 'PACE as % of Capital Stack')
    .set('landEquity', 'Land Equity ($)')
    .set('cashEquity', 'Cash Equity ($)')
    .set('projectType', 'Project Type')
    .set('entityName', 'Entity Name')
    .set('paceCompletionGuarantor', 'PACE Completion Guarantor')
    .set('ownershipInterest', 'Ownership Interest')
    .set('groundLeaseFinalExpirationDate', 'Ground Lease Final Expiration Date')
    .set('projectStartDate', 'Project Start Date')
    .set('projectFinishDate', 'Project Finish Date')
    .set('projectConstructionPeriod', ' Project Construction Period (Months)')
    .set('yearConstructed', 'Year Constructed')
    .set(
      'disbursementProjectFundingScope',
      'Disbursement - Project Funding Scope'
    )
    .set('undisbursedFunds', 'Undisbursed Funds (Updated 11/15/19)')
    .set('estAnnualSavings', 'Est. Annual Savings')
    .set('estAnnualSavingsKwh', 'Est. Annual Savings (kWh)')
    .set('savingsToInvestmentRatio', 'Savings-to-Investment Ratio')
    .set('priorityOfAssessmentLien', 'Priority of Assessment Lien')
    .set('taxPaymentDate', 'Tax Payment Date')
    .set('taxDelinquencyDates', 'Tax Delinquency Dates')
    .set('delinquencyPenalty', 'Delinquency Panalty')
    .set('billingMethod', 'Billing Method')
    .set('thirdPartyServicer', 'Third Party Servicer')
    .set('paceBondTrustee', 'PACE Bond trustee')
    .set('authority', 'Authority')
    .set('uwOccupancy', 'UW Occupancy')
    .set('certificateOfOccupancy', 'Certificate of Occupancy (Y/N)')
    .set('certificateOfOccupancyDate', 'Certificate of Occupancy Date')
    .set('spread', 'Spread')
    .set('subordinateDebtLender', 'Subordinate Debt Lender')
    .set(
      'subordinateDebtOutstandingBalance',
      'Subordinate Debt Outstanding Balance'
    )
    .set('subordinateDebtRate', 'Subordinate Debt Rate')
    .set('subordinateDebtTerm', 'Subordinate Debt Term')
    .set(
      'subordinateDebtAmortizationTerm',
      'Subordinate Debt Amortization Term'
    )
    .set('defaultTerms', 'Default Terms'),
  TRIAL_BALANCE_REPORT: new Map()
    .set('projectName', 'Project Name')
    .set('state', 'State')
    .set('propertyType', 'Property Type')
    .set('closingDate', 'Closing Date')
    .set('originalAmount', 'Original Amount')
    .set('maturityDate', 'Maturity Date')
    .set('borrowerBalance', 'Current Balance')
    .set('netCouponRate', 'Net Interest Rate')
    .set('interestRate', 'Interest Rate')
    .set('paidReceivedDate', 'Last Payment Received Date')
    .set('nextTaxPaymentDate', 'Next Assessment Due Date')
    .set('nextSecuredReceiptDate', 'Next Secured Receipt Date'),
  REMITTANCE_REPORT: new Map()
    .set('name', 'Project')
    .set('expectedPaymentDate', 'Secured Receipt Date')
    .set('receivedPaymentDate', 'Amount Received Date')
    .set('daysOverdue', 'Days Late')
    // .set('expectedPrincipalAmount', 'Expected Principal Amount')
    .set('receivedPrincipalAmount', 'Principal Amount')
    // .set('expectedInterestAmount', 'Expected Interest Amount')
    .set('receivedInterestAmount', 'Interest Amount')
    .set('receivedPehInterest', 'Capital Partner Interest')
    .set('receivedPeServicing', 'PACE EQUITY Servicing')
    .set('receivedAdminInterest', 'Admin Interest')
    // .set('expectedProgramAdminFee', 'Expected Program Admin Fee')
    .set('receivedProgramAdminFee', 'Program Admin Fee')
    // .set('expectedPeqAdminFee', 'Expected PEQ Admin Fee')
    .set('receivedPeqAdminFee', 'PEQ Admin Fee')
    .set('receivedLateFee', 'Late Fee/Other')
    // .set('expectedTotalAmount', 'Expected Total Amount')
    .set('receivedTotalAmount', 'Total Amount Received')
    .set('varianceTotalAmount', 'Total Amount Variance')
    .set('endingBalance', 'Total Principal Remaining')
    .set('notes', 'Notes'),
  DELINQUENCY_REPORT: new Map()
    .set('name', 'Project')
    .set('category', 'Category')
    .set('expectedAssessmentDueDate', 'Assessment Payment Date')
    .set('expectedPaymentDate', 'Secured Receipt Date')
    // .set('receivedPaymentDate', 'Amount Received Date')
    .set('daysOverdue', 'Days Overdue')
    .set('totalAmountFinanced', 'TAF')
    .set('currentLoanBalance', 'Current Loan Balance')
    .set('expectedPrincipalAmount', 'Principal Amount Due')
    // .set('receivedPrincipalAmount', 'Received Principal Amount')
    .set('expectedInterestAmount', 'Interest Amount Due')
    .set('pehInterest', 'Portfolio Interest')
    .set('peServicing', 'PE Servicing')
    .set('adminInterest', 'Admin Interest')
    // .set('receivedInterestAmount', 'Received Interest Amount')
    .set('expectedProgramAdminFee', 'Program Admin Fee')
    // .set('receivedProgramAdminFee', 'Received Program Admin Fee')
    .set('expectedPeqAdminFee', 'PEQ Admin Fee')
    // .set('receivedPeqAdminFee', 'Received PEQ Admin Fee')
    // .set('receivedLateFee', 'Late Fee/Other')
    .set('expectedTotalAmount', 'Total Amount Expected')
    // .set('receivedTotalAmount', 'Received Total Amount')
    .set('receivedTotalAmount', 'Total Amount Received')
    .set('billingParty', 'Collector of Pace')
    .set('county', 'County')
    .set('delinquencyNotes', 'Notes'),
  ASSESSMENT_BILLING_CASE_REPORT: new Map()
    // .set('dueDate', 'Due Date')
    // .set('projectName', 'Project Name')
    // .set('servicingType', 'Servicing Type')
    // .set('description', 'Short Description')
    // .set('caseNotes', 'Case Notes'),
    .set('projectName', 'Project')
    .set('portfolioName', 'Portfolio')
    .set('caseType', 'Case Type')
    .set('dueDate', 'Due Date')
    .set('closeDate', 'Completion Date')
    .set('caseNotes', 'Billing Notes'),
  INSURANCE_CERTIFICATION_CASE_REPORT: new Map()
    .set('dueDate', 'Due Date')
    .set('projectName', 'Project Name')
    // .set('servicingType', 'Servicing Type')
    .set('description', 'Short Description')
    .set('caseNotes', 'Case Notes'),
  DISBURSEMENT_REPORT: new Map()
    .set('drawNo', 'Draw Number')
    .set('drawDate', 'Draw Request Date')
    .set('paymentDate', 'Payment Date')
    // .set('currentAmount', 'Current Balance')
    .set('grossAmountRequested', 'Gross Amount Requested')
    // .set('remainingAmount', 'Remaining Balance')
    .set('drawStatus', 'Draw Status')
    .set('approvalNotes', 'Approval Notes'),
  OPEN_BALANCE_REPORT: new Map()
    .set('projectName', 'Project Name')
    .set('bankName', 'Project Acct Location')
    .set('directCost', 'Total Direct Cost')
    .set('disbursedAmount', 'Disbursements To Date')
    .set('openBalance', 'Open Balance')
    .set('percentDisbursed', 'Percent Disbursed')
    .set('paymentDate', 'Last Disbursed Date'),
  DBRS_REPORT: new Map()
    .set('projectName', 'Asset Name')
    .set('projectStatus', 'Project Status')
    .set('projectType', 'Project Type')
    .set(
      'originalEstimatedCompletionDate',
      'Original Estimated Completion Date'
    )
    .set('priorExpectedCompletionDate', 'Prior Expected Completion Date')
    .set('currentEstimatedCompletionDate', 'Current Estimated Completion Date')
    .set('asOfDate', 'As of Date')
    .set(
      'percentPaceConstructionComplete',
      'Percent PACE Construction Complete(by Dollar Cost)'
    )
    .set(
      'percentOverallProjectComplete',
      'Percent Overall Project Complete(by Dollar Cost)'
    )
    .set('totalDisbursementCommitment', 'Total Disbursement Commitment($)')
    .set(
      'paceProceedsFundedToBorrowerToDate',
      'PACE Proceeds Funded to Borrower to Date'
    )
    .set('paceProceedsToBeFunded', 'PACE Proceeds to Be Funded')
    .set('percentCommitmentDisbursed', '% Commitment Disbursed')
    .set(
      'firstAssessmentPaymentDate',
      'First Scheduled PACE Assessment Payment Date'
    )
    .set(
      'firstOwnerAssessmentPaymentDate',
      'First Scheduled PACE Assessment Payment Date(by Property Owner)'
    )
    .set('notes', 'Comments'),
  HIGH_RISK_REPORT: new Map()
    .set('projectName', 'Project Name')
    .set('riskCategory', 'Risk Category')
    .set('closeDate', 'Close Date')
    .set('lastSecuredReceiptDate', 'Last Secured Receipt Date')
    .set('nextSecuredReceiptDate', 'Next Secured Receipt Date')
    .set('totalAmountFinanced', 'Total Amount Financed')
    .set('totalRemainingBalance', 'Total Remaining Balance')
    .set('highRiskNotes', 'At Risk Notes'),
  LENDER_DATABASE: new Map()
    .set('name', 'Lender Name')
    .set('lastContactedDate', 'Date Last Contacted')
    .set('willingToConsent', 'Willing To Consent')
    .set('consentNotes', 'Consent Notes')
    .set('projectCount', 'Projects')
    .set('pricing', `Pricing (range)`)
    .set('terms', `Terms (range)`)
    .set('openToCollaboration', 'Preferred Lender')
    .set('propertyType', 'Property Types')
    .set('lenderType', 'Lender Types')
    .set('loanType', 'Loan Types')
    .set('statesLendIn', 'Stated Lend In')
    .set('loanSizeMin', 'Minimum Loan')
    .set('loanSizeMax', 'Maximum Loan')
    .set('loanToCostOrLoanToValue', 'LTC/LTV')
    .set('notes', 'Lender Notes')
    .set('contactDetails', 'Contacts'),
  TRANCHE_REPORT: new Map()
    .set('projectName', 'Project Name')
    .set('trancheName', 'Tranche Name')
    .set('securitizationDate', 'Securitization Date')
    .set('lastCapISweepDate', 'Last Cap-I Sweep Date')
    .set('beginningCapIBalance', 'Beginning Cap-I Balance')
    .set('pehInterest', 'Portfolio Interest')
    .set('peServicing', 'PACE Equity Servicing Interest')
    .set('adminInterest', 'Admin Interest')
    .set('totalCapIPeriod', 'Total Cap-I Period')
    .set('endingBalance', 'Ending Balance'),
  PREPAYMENT_DETAIL_REPORT: new Map()
    .set('portfolio', 'Portfolio')
    .set('project', 'Project')
    .set('closedDate', 'Close Date')
    .set('projectType', 'Project Type')
    .set('propertyType', 'Property Type')
    .set('prepaymentDate', 'Prepayment Date')
    .set('currentBalance', 'Prepayment Amount')
    .set('prepayPercent', 'Prepay %')
    .set('duration', 'Duration(years)'),
  PREPAYMENT_SUMMARY_REPORT: new Map()
    .set('year', 'Year')
    .set('beginningBalance', 'Begin Balance')
    .set('newOrigination', 'New Origination')
    .set('principalPayments', 'Principal Payments')
    .set('prepay', 'Prepay')
    .set('endingBalance', 'Ending Balance')
    .set('prepayPercentByYear', 'Prepay % by Year'),
};
export const ExcelTemplate = {
  REMITANCE_ROW: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'e8f5e9' },
  },
  REMITANCE_ROW_TOTAL: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'a5d6a7' },
  },
  GREY_COLUMN: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'd0cece' },
  },
  GREEN_COLUMN: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'e2efd9' },
  },
  BLUE_COLUMN: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'deeaf6' },
  },
  YELLOW_COLUMN: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'ffff00' },
  },
  LIGHT_YELLOW_COLUMN: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'fff9c4' },
  },
  BLUE_FONT: {
    color: { argb: '0070c0' },
    bold: true,
  },
  RED_FONT: {
    color: { argb: 'ff0000' },
    bold: true,
  },
  RED_FONT_NORMAL: {
    color: { argb: 'ff0000' },
  },
  BLACK_FONT_NORMAL: {
    color: { argb: '000000' },
  },
  PDF_BLUE_FILL: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'd9e0f0' },
  },
  PDF_DARK_BLUE_FILL: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '5799d5' },
  },
  BLUE_FILL: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '002060' },
  },
  LIGHT_BLUE_FILL: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'deeaf6' },
  },
  BLACK_FILL: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '000000' },
  },
  GREY_FILL: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'e7e6e6' },
  },
  LIGHT_GREY_FILL: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'f3f3f3' },
  },
  WHITE_FONT: {
    color: { argb: 'ffffff' },
    bold: true,
  },

  LIGHT_BLACK_FILL: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '80333f4f' },
    alignment: { vertical: 'middle', horizontal: 'center' },
  },

  TEXT_ALIGN_CENTER: {
    horizontal: 'center',
    wrapText: true,
    vertical: 'middle',
  },

  NOBORDER: {
    top: { style: 'none' },
    right: { style: 'none' },
    bottom: { style: 'none' },
    left: { style: 'none' },
  },

  BORDER: {
    TOP_LEFT: { top: { style: 'medium' }, left: { style: 'medium' } },
    TOP_RIGHT: { top: { style: 'medium' }, right: { style: 'medium' } },
    TOP: { top: { style: 'medium' } },
    BOTTOM: { bottom: { style: 'medium' } },
    LEFT: { left: { style: 'medium' } },
    RIGHT: { right: { style: 'medium' } },
    BOTTOM_LEFT: { bottom: { style: 'medium' }, left: { style: 'medium' } },
    BOTTOM_RIGHT: { bottom: { style: 'medium' }, right: { style: 'medium' } },
    TOP_BOTTOM: { bottom: { style: 'medium' }, top: { style: 'medium' } },
    ALL: {
      bottom: { style: 'medium' },
      top: { style: 'medium' },
      right: { style: 'medium' },
    },
  },
  CURRENCY: '"$"#,##0.00;"$("#,##0.00")"',
  RATE: '0.00"%"',
  NUMBER: '0.00',
  DATE: 'date',
  LEFT_ALIGN: 'left',
  CENTER_ALIGN: 'center',
  RIGHT_ALIGN: 'right',
  WRAP_TEXT: { wrapText: true },
  BOLD: { bold: true },
  UNDERLINE: { underline: true },
};

export const AssetDetailKeys = [
  {
    key: 'financingAmount',
    value: 'Financing Amount',
  },
  {
    key: 'financedAmount',
    value: 'Principle Amount Financed',
  },
  {
    key: 'interestAmount',
    value: 'Capitalized Interest',
  },
  {
    key: 'totalAmountFinanced',
    value: 'Total Amount Financed (Original Face)',
  },
  {
    key: 'capiBasisAmount',
    value: 'Basis for cap (if different)',
  },
  {
    key: 'assessmentTerms',
    value: 'Assessment Terms',
  },
  {
    key: 'assetRate',
    value: 'Asset Rate (Gross Coupon Rate)',
  },
  {
    key: 'adminRate',
    value: 'Admin Rate',
  },
  {
    key: 'peServicingRate',
    value: 'PEQ Admin Fee',
  },
  {
    key: 'netCouponRate',
    value: 'Net Coupon Rate',
  },
  {
    key: 'contractYears',
    value: 'Amortization Term (years)',
  },
  {
    key: 'paymentsPerYear',
    value: 'Payment per year',
  },
  // {
  //   key: 'piPayment',
  //   value: 'P&I Payment',
  // },
  {
    key: 'assessmentAnnualTotal',
    value: 'Average Annual Assessment Total',
  },
  {
    key: 'dates',
    value: 'Dates',
  },
  {
    key: 'datesNull',
    value: '',
  },
  {
    key: 'firstSecuredPaymentMonthDay',
    value: 'First Payment Date',
  },
  {
    key: 'secondSecuredPaymentMonthDay',
    value: 'Second Payment Date',
  },
  {
    key: 'closedDate',
    value: 'Close Date (Purchase Accrual Start Date)',
  },
  {
    key: 'fundingDate',
    value: 'Funding Date',
  },
  {
    key: 'firstPaymentYear',
    value: 'First Payment Year',
  },
  {
    key: 'capiEndDate',
    value: 'Cap I End Date (Purchase Accrual End date)',
  },
  {
    key: 'amortizationStartDate',
    value: 'Amortization Start Date',
  },
  {
    key: '6monthBefore',
    value: '6 months before first payment (default)',
  },
  {
    key: 'accruedInterestDays',
    value: 'Days of Accrued Interest (cap i)',
  },
  {
    key: 'maturityDate',
    value: 'Maturity Date',
  },
  {
    key: 'annualAdministartionFees',
    value: 'Annual Administaration Fees',
  },
  {
    key: 'peServicingFeePerYr',
    value: 'PEQ Admin Fee- added to assessment per year',
  },
  {
    key: 'percentageAddedToInterestRate',
    value: '% added to interest rate (per year)',
  },
  {
    key: 'isAdminInterest',
    value: 'Does Admin interest apply during capi period?',
  },
  {
    key: 'percentageAmtFunded',
    value: '% of the amount funded (per year)',
  },
  {
    key: 'percentagePiPayment',
    value: '% of the P&I payment',
  },
  // {
  //   key: 'customCalculatedAmt',
  //   value: 'Custom Calculated Amount (per year)'
  // },
  {
    key: 'fixedAmount',
    value: 'Fixed Amount (per year)',
  },
  //  {
  //   key: 'programFee',
  //   value: 'Program Fees (per year)'
  // },
  {
    key: 'firstPaymentOveride',
    value: 'First Payment Override',
  },
  {
    key: 'min',
    value: 'Min',
  },
  {
    key: 'max',
    value: 'Max',
  },
];
