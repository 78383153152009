import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services';
import { PaceEquityUtils } from '../utils';
import {PERMISSION} from '../constants';


@Injectable({
  providedIn: 'root',
})
export class RoleGuard  {
  constructor(
    private authService: AuthService,
    private router: Router,
    private paceEquityUtils: PaceEquityUtils
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url = state.url;
    let firstParam = url.split('/')[1];
    let paramIndex = firstParam.indexOf('?');
    if (paramIndex != -1) {
      firstParam = firstParam.substring(0, paramIndex);
    }
    let readyData: any;
    readyData = firstParam.split('-');
    if (readyData.length > 0) {
      for (let i = 0; i < readyData.length; i++) {
        if (readyData[i].substr(readyData[i].length - 1) == 's') {
          readyData[i] = readyData[i].substr(0, readyData[i].length - 1);
        }
      }
      readyData = readyData.join('_').toUpperCase();
    }
    if (this.authService.isRoleAuthorized(readyData)) {
      return true;
    } else {
      const loggedInUser = this.paceEquityUtils.getLoggedInUser();
      if (
        loggedInUser &&
        loggedInUser.permission.find(
          (x:any) =>
            x.permission?.code?.toUpperCase() === PERMISSION.DASHBOARD &&
            x.accessType
        )
      ) {
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate(['/welcome']);
      }
      return false;
    }
  }
}
