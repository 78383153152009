import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { map } from 'rxjs';
import { APICONSTANTS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClientService) {}
  saveContact(body:any) {
    return this.http.put(`${APICONSTANTS.CONTACT}`, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchContacts(body:any) {
    return this.http.get(`${APICONSTANTS.CONTACT}`,body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  deleteContact(id:number) {
    return this.http.remove(`${APICONSTANTS.CONTACT}/${id}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
