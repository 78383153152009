import {Injectable} from '@angular/core';
import {APICONSTANTS} from '../constants';
import {map} from 'rxjs/operators';
import {HttpClientService} from './http-client.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private http: HttpClientService) {}

  fetchRoles() {
    return this.http.get(`${APICONSTANTS.ROLE}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
