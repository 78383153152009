import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject, debounceTime, map } from 'rxjs';
import { GLOBAL } from 'src/app/constants';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  searchKeyword = new Subject<string>();
  searchKey = '';
  @Input() placeholder: string = 'Search';
  @Output() emitSearchKeyword = new EventEmitter<any>();

  constructor() {
    this.searchKeyword
      .pipe(
        map((event) => event),
        debounceTime(GLOBAL.debounceTime)
      )
      .subscribe((value) => {
        this.searchKey = value;
        return this.filter(value);
      });
  }

  ngOnInit(): void {}

  filter(value: any) {
    this.emitSearchKeyword.emit(value);
  }
}
