export const FETCH_PROFORMA_PHASES = 'FETCH_PROFORMA_PHASES';
export const FETCH_PROFORMA_FAILED = 'FETCH_PROFORMA_FAILED';
export const FETCH_PROFORMA_SUCCESS = 'FETCH_PROFORMA_SUCCESS';
export const FETCH_PROFORMA_LOADING = 'FETCH_PROFORMA_LOADING';
export const RESET_PHASE = 'RESET_PHASE';

export class PhaseRequestAction {
  readonly type = FETCH_PROFORMA_PHASES;
  constructor() {}
}

export class PhaseRequestActionSuccess {
  readonly type = FETCH_PROFORMA_SUCCESS;
  constructor(public payload: { phases: any[] }) {}
}

export class PhaseRequestActionFailed {
  readonly type = FETCH_PROFORMA_FAILED;
}

export class PhaseProformaLoading {
  readonly type = FETCH_PROFORMA_LOADING;
  constructor(public payload: boolean) {}
}

export class ResetPhase {
  readonly type = RESET_PHASE;
}
