<div class="input-group border bg-white rounded">
  <ng-container [ngSwitch]="header?.type">
    <ng-container *ngSwitchCase="'STRING'">
      <div
        class="input-group-prepend"
        *ngIf="header?.advancedSearch?.operations?.length"
      >
        <span class="input-group-text bg-white border-0">
          <div mdbDropdown class="dropdown" #dropdown>
            <a
              mdbDropdownToggle
              role="button"
              [class.disabled]="
                !header?.advancedSearch?.operations?.length || disabled
              "
            >
              <i class="icofont-search"></i>
            </a>

            <div mdbDropdownMenu class="dropdown-menu dropdown-primary">
              <a
                class="dropdown-item"
                role="button"
                *ngFor="let item of header?.advancedSearch?.operations"
              >
                <span class="mr-2 font-weight-bold">{{ item?.operand }}</span>
                <span>{{ item?.title }}</span>
              </a>
            </div>
          </div>
        </span>
      </div>
      <div
        class="input-group-append"
        [ngClass]="{
          'w-80': header?.advancedSearh?.operations?.length,
          'w-100': !header?.advancedSearch?.operations?.length
        }"
      >
        <ng-container *ngIf="header?.advancedSearch?.options?.length">
          <mdb-form-control>
            <mdb-select
              [multiple]="header?.advancedSearch?.isMultiSelect"
              [clearButton]="true"
              [filter]="true"
              [disabled]="disabled"
              [(ngModel)]="header.advancedSearch.selected"
              (ngModelChange)="filter($event)"
            >
              <mdb-option
                *ngFor="let option of header?.advancedSearch?.options"
                [value]="option.value"
                >{{ option.label }}</mdb-option
              >
            </mdb-select>
          </mdb-form-control>
        </ng-container>
      </div>
      <div
        class="input-group-append"
        [ngClass]="{
          'w-80': header?.advancedSearh?.operations?.length,
          'w-100': !header?.advancedSearch?.operations?.length
        }"
      >
        <ng-container *ngIf="!header?.advancedSearch?.options?.length">
          <input
            type="text"
            class="form-control bg-white border-0 px-0"
            id="searchKeyword"
            autocomplete="off"
            [(ngModel)]="header.advancedSearch.selected"
          />
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'NUMBER'">
      <div
        class="input-group-prepend"
        style="width: 25% !important"
        *ngIf="header?.advancedSearch?.operations?.length"
      >
        <span class="input-group-text bg-white border-0">
          <div mdbDropdown class="dropdown" #dropdown>
            <a
              mdbDropdownToggle
              role="button"
              [class.disabled]="
                !header?.advancedSearch?.operations?.length || disabled
              "
            >
              <i
                class="{{
                  handleSelected()
                    ? selectedOperations?.icon
                    : 'fa-sliders-h fas'
                }}"
              ></i>
            </a>

            <div mdbDropdownMenu class="dropdown-menu dropdown-primary">
              <a
                class="dropdown-item"
                role="button"
                (click)="applyOperator(item)"
                *ngFor="let item of header?.advancedSearch?.operations"
              >
                <i class="{{ item?.icon }} mr-1"></i>
                <span>{{ item?.title }}</span>
              </a>

              <a class="dropdown-item" role="button" (click)="reset()">
                <i class="icofont-close-line mr-1"></i>Reset</a
              >
            </div>
          </div>
        </span>
      </div>
      <ng-container
        *ngIf="
          displayOperatorInput && this.selectedOperations?.operand === 'x-y'
        "
      >
        <div
          class="input-group-append"
          style="width: 75% !important"
          [mdbPopover]="popTemplate"
          placement="bottom"
        >
          <input
            [(ngModel)]="rangeValue"
            type="text"
            class="form-control bg-white border-0 px-2"
            id="range_x_y"
            readonly
            autocomplete="off"
            (ngModelChange)="changeValue($event)"
          />
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          !this.selectedOperations || this.selectedOperations?.operand !== 'x-y'
        "
      >
        <div
          class="input-group-append"
          [style.width]="
            header?.advancedSearch?.operations?.length
              ? '75% !important'
              : '100% !important'
          "
        >
          <mdb-form-control>
            <mdb-select
              [multiple]="header?.advancedSearch?.isMultiSelect"
              [clearButton]="true"
              [filter]="true"
              [disabled]="disabled"
              [(ngModel)]="header.advancedSearch.selected"
              (ngModelChange)="filter($event)"
            >
              <mdb-option
                *ngFor="let option of header?.advancedSearch?.options"
                [value]="option.value"
                >{{ option.label }}</mdb-option
              >
            </mdb-select>
          </mdb-form-control>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'BOOLEAN'">
      <ng-container *ngIf="header?.advancedSearch?.options?.length">
        <mdb-form-control>
          <mdb-select
            [multiple]="header?.advancedSearch?.isMultiSelect"
            [clearButton]="true"
            [filter]="true"
            [disabled]="disabled"
            [(ngModel)]="header.advancedSearch.selected"
            (ngModelChange)="filter($event)"
          >
            <mdb-option
              *ngFor="let option of header?.advancedSearch?.options"
              [value]="option.value"
              >{{ option.label }}</mdb-option
            >
          </mdb-select>
        </mdb-form-control>
      </ng-container>
      <ng-container *ngIf="!header?.advancedSearch?.options?.length">
        <input
          [ngModel]="searchKeyword | async"
          type="text"
          class="form-control bg-white border-0 px-0"
          id="searchKeyword"
          autocomplete="off"
          (ngModelChange)="searchKeyword.next($event)"
        />
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #popTemplate>
  <form [formGroup]="rangeForm">
    <mdb-multi-range
      id="multi-range"
      formControlName="range"
      [min]="min"
      [max]="max"
      [tooltip]="true"
      [startValues]="splitRange()"
      step="1"
      (changeValue)="onRangeValueChange()"
    ></mdb-multi-range>
  </form>
</ng-template>
