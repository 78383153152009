import { Injectable } from '@angular/core';
import { APICONSTANTS } from '../constants';
import { map } from 'rxjs/operators';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  constructor(private http: HttpClientService) {}

  getBaseURL = (projectId: number, assetId: number, assessmentId?: number) => {
    return `${APICONSTANTS.PROJECT}/${projectId}${
      APICONSTANTS.ASSET
    }/${assetId}${APICONSTANTS.ASSESSMENTS}${
      assessmentId ? '/' + assessmentId : ''
    }`;
  };

  checkAmoEndBalance(
    projectId: number,
    assetId: number,
    assessmentId: number,
    body: any
  ) {
    return this.http
      .post(
        `${this.getBaseURL(
          projectId,
          assetId,
          assessmentId
        )}/check-ending-balance`,
        body
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchPricing(projectId: number, assetId: number, assessmentId: number) {
    return this.http
      .get(`${this.getBaseURL(projectId, assetId, assessmentId)}/pricing`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  savePricing(
    projectId: number,
    assetId: number,
    assessmentId: number,
    body: any
  ) {
    if (!body.id) {
      return this.addPricing(projectId, assetId, assessmentId, body);
    } else {
      return this.updatePricing(
        projectId,
        assetId,
        assessmentId,
        body.id,
        body
      );
    }
  }

  updatePricing(
    projectId: number,
    assetId: number,
    assessmentId: number,
    pricingId: number,
    body: any
  ) {
    return this.http
      .put(
        `${this.getBaseURL(
          projectId,
          assetId,
          assessmentId
        )}/pricing/${pricingId}`,
        body
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addPricing(
    projectId: number,
    assetId: number,
    assessmentId: number,
    body: any
  ) {
    return this.http
      .post(
        `${this.getBaseURL(projectId, assetId, assessmentId)}/pricing`,
        body
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveAssessmentScenario(
    projectId: number,
    assetId: number,
    assessmentId: number,
    body: any,
    isEdit: boolean = false
  ) {
    if (!isEdit) {
      return this.addAssessmentScenario(projectId, assetId, body);
    } else {
      return this.updateAssessmentScenario(projectId, assetId, assessmentId, {
        ...body,
        id: assessmentId,
      });
    }
  }

  fetchPriceBuildup(
    projectId: number,
    assetId: number,
    assessmentId: number,
    pricingId: number
  ) {
    return this.http
      .get(
        `${this.getBaseURL(
          projectId,
          assetId,
          assessmentId
        )}/pricing/${pricingId}/price-buildups`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  calculatePricing(
    projectId: number,
    assetId: number,
    assessmentId: number,
    data: any,
    closedDate: any
  ) {
    return this.http
      .post(
        `${this.getBaseURL(
          projectId,
          assetId,
          assessmentId
        )}/pricing/calculate?closedDate=${closedDate}`,
        data
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchAllExistingAssessmentScenarios(
    projectId: number,
    assetId: number,
    queryParams = { hideScenarios: false }
  ) {
    return this.http
      .get(`${this.getBaseURL(projectId, assetId)}`, queryParams)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  // fetchAllFavoriteAssessment(
  //   projectId: number,
  //   assetId: number,
  //   queryParams = { isFavorite: true }
  // ) {
  //   return this.http
  //     .get(`${this.getBaseURL(projectId, assetId)}`, queryParams)
  //     .pipe(
  //       map((response: any) => {
  //         return response;
  //       })
  //     );
  // }

  fetchAllAssessment(projectId: number, assetId: number) {
    return this.http.get(`${this.getBaseURL(projectId, assetId)}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveSelectedAssessmentScenarios(body: {
    id: any;
    projectId: any;
    assessmentIds: number[];
  }) {
    return this.http
      .patch(`${APICONSTANTS.PROJECT}/${body.projectId}/update-phase`, body)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addAssessmentScenario(projectId: number, assetId: number, body: any) {
    return this.http.post(`${this.getBaseURL(projectId, assetId)}`, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateAssessmentScenario(
    projectId: number,
    assetId: number,
    assessmentId: number,
    body: any
  ) {
    return this.http
      .put(`${this.getBaseURL(projectId, assetId, assessmentId)}`, body)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteAssessmentScenario(
    projectId: number,
    assetId: number,
    assessmentId: number
  ) {
    return this.http
      .remove(`${this.getBaseURL(projectId, assetId, assessmentId)}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchAssessmentDetails(
    projectId: number,
    assetId: number,
    assessmentId: number
  ) {
    return this.http
      .get(`${this.getBaseURL(projectId, assetId, assessmentId)}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  scheduleAmortization(
    projectId: number,
    assetId: number,
    assessmentId: number,
    body: any
  ) {
    return this.http
      .post(
        `${this.getBaseURL(projectId, assetId, assessmentId)}/amortize`,
        body
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getAmortizationSchedules(
    projectId: number,
    assetId: number,
    assessmentId: number
  ) {
    return this.http
      .get(
        `${this.getBaseURL(
          projectId,
          assetId,
          assessmentId
        )}/amortization-schedules`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getPaymentSchedules(
    projectId: number,
    assetId: number,
    assessmentId: number
  ) {
    return this.http
      .get(
        `${this.getBaseURL(projectId, assetId, assessmentId)}/payment-schedules`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  handleAssessmentFavorite(
    projectId: number,
    assetId: number,
    assessmentId: number,
    isFavorite: boolean
  ) {
    return this.http
      .patch(
        `${this.getBaseURL(projectId, assetId, assessmentId)}/mark-favorite`,
        { isFavorite }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchAssessmentSummaryReport(
    projectId: number,
    assetId: number,
    assessmentId: number
  ) {
    return this.http
      .get(
        `${this.getBaseURL(projectId, assetId, assessmentId)}/summary-report`,
        {}
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  verifyState(projectId: number, body: any) {
    return this.http
      .post(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${body.assetId}${APICONSTANTS.ASSESSMENTS}${APICONSTANTS.VERIFY_STATE}`,
        body
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchPriceAdjustment(
    projectId: number,
    assetId: number,
    assessmentId: number
  ) {
    return this.http
      .get(
        `${this.getBaseURL(
          projectId,
          assetId,
          assessmentId
        )}/pricing/price-adjustments`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  calculateAdjustment(
    projectId: number,
    assetId: number,
    assessmentId: number,
    body: any
  ) {
    return this.http
      .put(
        `${this.getBaseURL(
          projectId,
          assetId,
          assessmentId
        )}/pricing/price-adjustments/${
          body.priceAdjustments.assessmentPricingAdjustmentId
        }`,
        body
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updatePublishPricing(
    projectId: number,
    assetId: number,
    assessmentId: number,
    assessmentPriceBuildupId: any
  ) {
    return this.http
      .post(
        `${this.getBaseURL(
          projectId,
          assetId,
          assessmentId
        )}/pricing/price-buildup/publish`,
        assessmentPriceBuildupId
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updatePricingApproach(
    projectId: number,
    assetId: number,
    assessmentId: number,
    body: any
  ) {
    return this.http
      .post(
        `${this.getBaseURL(projectId, assetId, assessmentId)}/pricing-approach`,
        body
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchAssessmentReports(assetId: number, assessmentId: number) {
    return this.http
      .get(
        `${APICONSTANTS.ASSET}/${assetId}/assessments/${assessmentId}/assessment-report`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchFavoriteAssessmentsCombined(assetId: number, body: any) {
    return this.http
      .post(`${APICONSTANTS.ASSET}/${assetId}/summary-report`, body)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
