import { momentDateFormat } from './moment.util';

export const updateFilterList = (
  field: any,
  formField: any,
  value: any,
  optionList: any[] = [],
  filterList: any[] = []
) => {
  if (value && typeof value === 'object') {
    // for date range
    if (value['from'] && value['to']) {
      addFilter(field, formField, value, optionList, filterList);
    } else {
      Object.keys(value).forEach((y) => {
        let selectedValue = value[y];
        addFilter(field, formField, selectedValue, optionList, filterList);
      });
    }
    if (
      formField === 'range' ||
      formField === 'closeRange' ||
      formField === 'pdaRange'
    )
      addRangeFilter(field, formField, value, filterList);
  } else if (
    value &&
    (typeof value === 'string' || typeof value === 'number')
  ) {
    addFilterText(field, formField, value, filterList);
  } else {
    addFilter(field, formField, value, optionList, filterList);
  }
};

const addFilterText = (
  field: any,
  formField: any,
  value: any,
  filterList: any[] = []
) => {
  if (value) {
    let index = filterList.findIndex(
      (x) =>
        x.field == field &&
        x.value == value &&
        formField != 'range' &&
        formField != 'closeRange' &&
        formField != 'pdaRange'
    );
    if (index == -1) {
      let data = {
        field: field,
        formField: formField,
        value: value,
        label: (typeof value) == 'string' ? value : '$' + value,
      };
      filterList.push(data);
    }
  }
};

const addFilter = (
  field: any,
  formField: any,
  value: any,
  optionList: any[] = [],
  filterList: any[] = []
) => {
  let valueIndex = optionList.findIndex((x) => x.value == value);
  if (valueIndex > -1) {
    let index = filterList.findIndex(
      (x) => x.field == field && x.value == value && formField != 'range'
    );
    if (index == -1) {
      let data = {
        field: field,
        formField: formField,
        value: value,
        label: optionList[valueIndex].label,
      };
      filterList.push(data);
    }
  }
};

const addRangeFilter = (
  field: any,
  formField: any,
  value: any,
  filterList: any[] = []
) => {
  let data = {
    field: field,
    formField: formField,
    value: `${value[0]} - ${value[1]}`,
    label: `${momentDateFormat(value[0], 'L')} to ${momentDateFormat(
      value[1],
      'L'
    )}`,
  };
  filterList.push(data);
};

export const getFilterStatus = (selectedOptions: any, value: any) => {
  // if (selectedOptions === 'Yes') {
  // }
  if (value && typeof value === 'object' && selectedOptions) {
    let dataValue = value.length ? true : false;
    for (let element of value) {
      dataValue =
        selectedOptions && selectedOptions.length
          ? selectedOptions.includes(element)
          : true;
      if (dataValue) {
        break;
      }
    }
    return dataValue;
  } else {
    return selectedOptions && selectedOptions.length
      ? selectedOptions.includes(value)
      : true;
  }
};

export const getRucaCodeFilterStatus = (selectedOptions: any, value: any) => {
  if (value && selectedOptions?.length > 0) {
    const { less_than, greater_than, is_equal } = selectedOptions[0];
    // check is between
    if (greater_than && less_than && !is_equal) {
      return value > greater_than && value < less_than;
    }
    // check greater than
    if (greater_than && !less_than && !is_equal) {
      return value > greater_than;
    }
    // check less than
    if (less_than && !greater_than && !is_equal) {
      return value < less_than;
    }
    // check is equal
    else if (value == is_equal) {
      return true;
    }
  }
  return true;
};
