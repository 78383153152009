// export const PROPERTY_TYPE_LIST = [
//   {value: 'Retail', label: 'Retail'},
//   {value: 'Office', label: 'Office'},
//   {value: 'Multifamily', label: 'Multifamily'},
//   {value: 'Hotel', label: 'Hotel'},
//   {value: 'Assisted Living', label: 'Assisted Living'},
//   {
//     value: 'Agriculture & Dairy facilities/Farms',
//     label: 'Agriculture & Dairy facilities/Farms',
//   },
//   {value: 'Hospitals', label: 'Hospitals'},
//   {value: 'Industrial', label: 'Industrial'},
//   {value: 'Non-profit', label: 'Non-profit'},
//   {value: 'Schools', label: 'Schools'},
//   {value: 'Universities', label: 'Universities'},
//   {value: 'Special Purpose / Other', label: 'Special Purpose / Other'},
//   {value: 'Almost Any', label: 'Almost Any'},
//   {value: 'Senior/Assisted Living', label: 'Senior/Assisted Living'},
//   {value: 'Tax Credit', label: 'Tax Credit'},
//   {value: 'Mixed Use', label: 'Mixed Use'},
//   {value: 'Parking Garage', label: 'Parking Garage'},
// ];

export const PROPERTY_PROJECT_STATUS = [
  {label: 'Completed', value: 'Completed'},
  {label: 'In Progress', value: 'In Progress'},
  {label: 'Not Started Yet', value: 'Not Started Yet'},
];
export const PROPERTY_STATUS = [
  {label: 'Existing', value: 'Existing'},
  {label: 'Gut Rehab', value: 'Gut Rehab'},
  {label: 'Ground-up Construction', value: 'Ground-up Construction'},
  {label: 'Land', value: 'Land'},
];
export const OCCUPANCY_TYPE = [
  {label: 'Owner-occupied', value: 'Owner-occupied'},
  {label: 'Single tenant', value: 'Single tenant'},
  {label: 'Multi tenant', value: 'Multi tenant'},
  {label: 'Anchor tenant', value: 'Anchor tenant'},
];
export const ENVIRONMENTAL_REPORT_TYPE = [
  {label: 'Phase 1', value: 'Phase 1'},
  {label: 'Phase 2', value: 'Phase 2'},
  {label: 'Database Search', value: 'Database Search'},
  {label: 'Other', value: 'Other'},
];
export const RUCA_CODE = [
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: '4', value: 4},
  {label: '5', value: 5},
  {label: '6', value: 6},
  {label: '7', value: 7},
  {label: '8', value: 8},
  {label: '9', value: 9},
  {label: '10', value: 10},
];
