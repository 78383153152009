const resolveDashboardFilterData = (distinctName: any) => ({
  distinctName,
  keyword: '',
  operator: '',
  type: '',
  isMultiSelect: false,
  operations: [],
});
export const DASHBOARD_FILTER = [
  resolveDashboardFilterData('asset_property_rucaCode'),
  resolveDashboardFilterData('project_marketLeaderId'),
  resolveDashboardFilterData('asset_impact_eeStatus'),
  resolveDashboardFilterData('asset_impact_cirrusInterest'),
  resolveDashboardFilterData('asset_impact_cirrusStatus'),
  resolveDashboardFilterData('asset_property_buildingType'),
  resolveDashboardFilterData('asset_pricing_pricingScenario'),
  resolveDashboardFilterData('asset_property_isEsoteric'),
];
