import { Action, createSelector } from '@ngrx/store';
import {
  FETCH_PROFORMA_PHASES,
  FETCH_PROFORMA_FAILED,
  FETCH_PROFORMA_SUCCESS,
  FETCH_PROFORMA_LOADING,
  RESET_PHASE,
} from '../action/phase-action';

export interface PhaseReducerState {
  phases: any[];
  error: null | object;
  loading: boolean;
}

const initialState: PhaseReducerState = {
  phases: [],
  error: null,
  loading: false,
};

export function PhaseReducer(
  state = initialState,
  action: any
): PhaseReducerState {
  switch (action.type) {
    case FETCH_PROFORMA_PHASES: {
      return state;
    }
    case FETCH_PROFORMA_LOADING: {
      return { ...state, loading: action.payload };
    }
    case FETCH_PROFORMA_SUCCESS: {
      return { ...state, phases: action.payload.phases };
    }
    case RESET_PHASE: {
      return { ...state, phases: [] };
    }
    default:
      return state;
  }
}

export const getPhases = (state: PhaseReducerState) => state.phases;
