import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private title: Title) {
    this.title.setTitle(
      `Proforma${
        environment.env != 'prod'
          ? ' (' + environment.env.toUpperCase() + ')'
          : ''
      }`
    );
  }
  ngOnInit() {}
  // title = 'pace-equity-performa';
}
