import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import { PasswordValidationDirective } from 'src/app/directives/password-match.directive';
import { AuthService, ToasterService } from 'src/app/services';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent {
  changePasswordForm: FormGroup = new FormGroup({});
  buttonDisabled = false;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private toastService: ToasterService,
    private authService: AuthService,
    private changePasswordModal: MdbModalRef<ChangePasswordModalComponent>
  ) {}

  ngOnInit() {
    this.setChangePasswordForm();
  }

  setChangePasswordForm() {
    this.changePasswordForm = this.fb.group(
      {
        oldPassword: ['', [Validators.required]],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$'
            ),
          ],
        ],
        confirmPassword: ['', Validators.required],
      },
      { validator: PasswordValidationDirective.MatchPassword }
    );
  }
  hideModal() {
    this.changePasswordModal.close();
  }

  changePassword() {
    this.submitted = true;
    if (this.changePasswordForm.valid) {
      this.buttonDisabled = true;
      const model = {
        oldPassword: this.changePasswordForm.value.oldPassword,
        newPassword: this.changePasswordForm.value.password,
      };
      this.authService
        .changePassword(model)
        .pipe(
          finalize(() => {
            this.buttonDisabled = false;
          })
        )
        .subscribe((response: any) => {
          if (
            response &&
            response.success &&
            response.data &&
            !response.data.message
          ) {
            this.toastService.success(
              'Password changed successfully',
              'Change Password'
            );
            this.hideModal();
            this.authService.logout();
            this.submitted = false;
          } else if (
            response &&
            response.success &&
            response.data &&
            response.data.message
          ) {
            this.toastService.error(response.data.message, 'Change Password');
          }
        });
    }
  }
}
