import moment from 'moment';

export const momentIsValid = (date: any, format: string, required: boolean) => {
  return date
    ? moment(date, format, true).isValid() && momentIsSameorAfterMinDate(date)
    : !required;
};

export const momentIsSameorAfterMinDate = (date: Date) => {
  return moment(date).isSameOrAfter('1753-01-01');
};

export const momentDateFormat = (date = new Date(), format = 'MM/DD/YYYY') => {
  return moment(date).format(format);
};

export const momentDate = (date = new Date(), format = 'MM/DD/YYYY') => {
  return moment(date, format, true);
};

export const momentIsSameOrAfter = (
  checkDate: any,
  withDate: any,
  granularity: moment.unitOfTime.StartOf = 'days'
) => {
  return momentDate(checkDate).isSameOrAfter(momentDate(withDate), granularity);
};

export const momentIsAfter = (
  checkDate: any,
  withDate: any,
  granularity: moment.unitOfTime.StartOf = 'days'
) => {
  return momentDate(checkDate).isAfter(momentDate(withDate), granularity);
};

export const momentIsSameOrBefore = (
  checkDate: any,
  withDate: any,
  granularity: moment.unitOfTime.StartOf = 'days'
) => {
  return momentDate(checkDate).isSameOrBefore(
    momentDate(withDate),
    granularity
  );
};

export const momentIsBetween = (
  checkDate: any,
  startDate: any,
  endDate: any,
  granularity: moment.unitOfTime.StartOf = 'days',
  inclusivity: '[]' | '()' | '[)' | '(]' = '[]' //'[',']' means inclusive, '(',')' means exclusive
) => {
  return momentDate(checkDate).isBetween(
    moment(startDate),
    moment(endDate),
    granularity,
    inclusivity
  );
};

export const momentStartOf = (
  granularity: moment.unitOfTime.StartOf = 'days',
  format = 'MM/DD/YYYY'
) => {
  return moment().startOf(granularity).format(format);
};

export const momentEndOf = (
  granularity: moment.unitOfTime.StartOf = 'days',
  format = 'MM/DD/YYYY'
) => {
  return moment().endOf(granularity).format(format);
};

export const momentAdd = (
  date: any,
  amount: any,
  granularity: moment.unitOfTime.DurationConstructor,
  format = 'MM/DD/YYYY'
) => {
  return moment(date).add(amount, granularity).format(format);
};

export const momentAddEndOf = (
  date: any,
  amount: any,
  granularity: moment.unitOfTime.DurationConstructor,
  format = 'MM/DD/YYYY'
) => {
  return moment(date)
    .add(amount, granularity)
    .endOf(granularity)
    .format(format);
};

export const momentConvert = (date: Date) => {
  return moment(date).format('MMMM YYYY');
};

export const getTimestamp = () => {
  return moment(new Date()).format('MM/DD/YYYY hh:mm:ss A');
};

export const longDateOption = (date: Date) => {
  return moment(date).format('MMMM D, YYYY');
};

export const getPreviousFormattedDate = (
  date: Date,
  amount: any,
  granularity = 'days'
) => {
  const n = moment(date).subtract(amount, granularity);
  return moment(n).format('MMMM yyyy');
};

export const getDate = (label: string) => {
  const currentDate = momentDateFormat();
  const startOfMonth = momentStartOf('month');
  const endOfMonth = momentEndOf('month');
  const startDateOfThisQuarter = momentStartOf('quarter');
  const endDateOfThisQuarter = momentEndOf('quarter');
  const startOfThisYear = momentStartOf('year');
  const endOfThisYear = momentEndOf('year');
  const startOfLastMonth = momentAdd(momentStartOf('month'), -1, 'months');
  const endOfLastMonth = momentAddEndOf(momentEndOf('month'), -1, 'months');
  const startDateOfLastQuarter = momentAdd(
    momentStartOf('quarter'),
    -1,
    'quarters'
  );
  const endDateOfLastQuarter = momentAddEndOf(
    momentEndOf('quarter'),
    -1,
    'quarters'
  );
  const startOfNextQuarter = momentAdd(momentStartOf('quarter'), 1, 'quarters');
  const endOfNextQuarter = momentAddEndOf(
    momentEndOf('quarter'),
    1,
    'quarters'
  );
  const startOfLastYear = momentAdd(momentStartOf('year'), -1, 'years');
  const endOfLastYear = momentAddEndOf(momentEndOf('year'), -1, 'years');
  switch (label) {
    case 'This Month':
      return {
        from: startOfMonth,
        to: endOfMonth,
      };
    case 'This Month-To-Date':
      return {
        from: startOfMonth,
        to: currentDate,
      };
    case 'This Quarter':
      return {
        from: startDateOfThisQuarter,
        to: endDateOfThisQuarter,
      };
    case 'This Quarter-To-Date':
      return {
        from: startDateOfThisQuarter,
        to: currentDate,
      };
    case 'This Year':
      return {
        from: startOfThisYear,
        to: endOfThisYear,
      };
    case 'Last Month':
      return {
        from: startOfLastMonth,
        to: endOfLastMonth,
      };
    case 'Last Quarter':
      return {
        from: startDateOfLastQuarter,
        to: endDateOfLastQuarter,
      };
    case 'Next Quarter':
      return {
        from: startOfNextQuarter,
        to: endOfNextQuarter,
      };
    case 'This Quarter And Next Quarter':
      return {
        from: startDateOfThisQuarter,
        to: endOfNextQuarter,
      };
    case 'Last Year':
      return {
        from: startOfLastYear,
        to: endOfLastYear,
      };
    default:
      return {};
  }
};
