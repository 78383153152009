import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants/api.constant';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClientService) {}

  fetchUser(queryParams = {}) {
    return this.http.get(`${APICONSTANTS.USER}`, queryParams).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchMarketLeaders(queryParams = {}) {
    return this.http
      .get(`${APICONSTANTS.USER}/market-leaders`, queryParams)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchTransactionManagers(queryParams = {}) {
    return this.http
      .get(`${APICONSTANTS.USER}/transaction-managers`, queryParams)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  checkUserPermission() {
    return this.http.get(`${APICONSTANTS.USER_PERMISSION}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  deleteUserFromShareAccess(id: number) {
    return this.http.remove(`${APICONSTANTS.USER}/share-access/${id}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  searchUser(searchText: string) {
    return this.http.get(`${APICONSTANTS.USER}/search/${searchText}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveShareAccess(body: any) {
    return this.http.post(`${APICONSTANTS.USER}/share-access`, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchUserById(id:number) {
    return this.http.get(`${APICONSTANTS.USER}/${id}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateUser(user:any) {
    return this.http.put(`${APICONSTANTS.USER}/${user.id}`, user).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
