import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private http: HttpClientService) {}

  saveProject(project: any, projectId: number, isEdit = false) {
    if (!isEdit) {
      return this.addProject(project);
    } else {
      return this.updateProject(project, projectId);
    }
  }

  addProject(project: any) {
    return this.http.post(`${APICONSTANTS.PROJECT}`, project).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateProject(project: any, projectId: number) {
    return this.http.put(`${APICONSTANTS.PROJECT}/${projectId}`, project).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchProjects(queryParams = {}) {
    return this.http.get(`${APICONSTANTS.PROJECT}`, queryParams).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchProjectsById(projectId: number, queryParams = {}) {
    return this.http
      .get(`${APICONSTANTS.PROJECT}/${projectId}`, queryParams)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchProjectPhases() {
    return this.http.get(`${APICONSTANTS.PHASE}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateProjectPhase(projectId: number, body: any) {
    return this.http
      .patch(`${APICONSTANTS.PROJECT}/${projectId}/update-phase`, body)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchQualMatrix(projectId: number) {
    return this.http.get(`${APICONSTANTS.PROJECT}/${projectId}/qm`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchDefaultContactGroup(projectId: number) {
    return this.http
      .get(`${APICONSTANTS.PROJECT}/${projectId}/contacts/default-groups`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchProjectContacts(projectId: number) {
    return this.http.get(`${APICONSTANTS.PROJECT}/${projectId}/contacts`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchQmReportData(projectId: number) {
    return this.http.get(`${APICONSTANTS.PROJECT}/${projectId}/qm/report`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  verifyState(projectId: number, assetId: number, programId: number) {
    return this.http
      .post(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}${APICONSTANTS.VERIFY_STATE}`,
        {
          assetId,
          programId,
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchProjectPandL(projectId: number, assetId: number) {
    return this.http
      .get(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}/p-and-l`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateProjectPandL(projectId: number, assetId: number, body: any) {
    return this.http
      .put(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}/p-and-l`,
        body
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
