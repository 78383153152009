import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkScrollableModule } from '@angular/cdk/scrolling';

import { HttpClientModule } from "@angular/common/http";
import { MDBAngularModule } from "./mdb-angular.module";

import * as guards from '../guards';
import { NgxModule } from "./ngx-module";
import { FileUploadModule } from "@iplab/ngx-file-upload";

@NgModule({
    declarations: [
       
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MDBAngularModule,
        NgxModule,
        CdkScrollableModule,
        FileUploadModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MDBAngularModule,
        NgxModule,
        CdkScrollableModule,
        FileUploadModule
    ],
    providers: [
        guards.AuthGuard,
        guards.RoleGuard
    ],
})
export class SharedModule { }