<div
  [ngStyle]="
    !fullscreen
      ? {
          height: '100px',
          width: '100%',
          'z-index': '1029'
        }
      : {
          height: '100%',
          width: '100%',
          'z-index': '1029'
        }
  "
  #container
>
  <mdb-loading
    [show]="true"
    [backdropClass]="!fullscreen ? 'custom-loader' : ''"
    [container]="container"
    [backdrop]="backdrop"
    [fullscreen]="fullscreen"
  >
    <div class="loading-spinner">
      <div
        class="spinner-grow loading-icon"
        role="status"
        style="color: #3571ba; margin-right: 70px"
      ></div>
    </div>
    <div class="loading-spinner">
      <div
        class="spinner-grow loading-icon"
        style="color: #28a745; margin-left: 70px"
        role="status"
      ></div>
    </div>
    <div class="loading-spinner">
      <div
        class="spinner-grow loading-icon"
        style="color: #5a5; margin: 10px"
        role="status"
      ></div>
    </div>
  </mdb-loading>
</div>
