export const PERMISSION = {
  DASHBOARD: 'DASHBOARD',
  PORTFOLIO: 'PORTFOLIO',
  PROJECT: 'PROJECT',
  USER: 'USER',
  ROLE_PERMISSION: 'ROLE_PERMISSION',
  REPORT: 'REPORT',
  COMPANY: 'COMPANY',
  DOCUMENT_LIBRARY: 'DOCUMENT_LIBRARY',
  PROGRAM: 'PROGRAM',
  CASE_MANAGEMENT: 'CASE_MANAGEMENT',
  CONTACT_DIRECTORY: 'CONTACT_DIRECTORY',
  LENDER_DATABASE: 'LENDER_DATABASE',
  STATE_DATABASE: 'STATE_DATABASE',
  PROJECT_DETAIL: 'PROJECT_DETAIL',
  AMO_SCHEDULE: 'AMO_SCHEDULE',
  PAYMENT_SCHEDULE: 'PAYMENT_SCHEDULE',
  PAYMENT_DETAIL: 'PAYMENT_DETAIL',
  CASE_HISTORY: 'CASE_HISTORY',
  DISBURSEMENT: 'DISBURSEMENT',
  CONTACTS: 'CONTACTS',
  QM: 'QM',
  ASM: 'ASM',
  DSM: 'DSM',
  ASSESSMENTS: 'ASSESSMENTS',
  ASSET_DETAIL: 'ASSET_DETAIL',
  PROPERTY: 'PROPERTY',
  CREDIT_METRICS: 'CREDIT_METRICS',
  CAPITAL_STACK: 'CAPITAL_STACK',
  SERVICING_AND_PACE_ASSETS: 'SERVICING_AND_PACE_ASSETS',
  IMPACT_DATA: 'IMPACT_DATA',
  DISBURSEMENT_DASHBOARD: 'DISBURSEMENT_DASHBOARD',
  CONSTRUCTION_PROGRESS: 'CONSTRUCTION_PROGRESS',
  PROGRAM_DASHBOARD: 'PROGRAM_DASHBOARD',
  PROGRAM_CONTACTS: 'PROGRAM_CONTACTS',
  PROGRAM_SERVICING: 'PROGRAM_SERVICING',
  PROGRAM_FEES: 'PROGRAM_FEES',
  OPERATIONS: 'OPERATIONS',
  DRAWS: 'DRAWS',
  ASSESSMENT_BILLING_OLD: 'ASSESSMENT_BILLING_OLD',
  ASSESSMENT_BILLING: 'ASSESSMENT_BILLING',
  INVESTOR_BILLING: 'INVESTOR_BILLING',
  INSURANCE: 'INSURANCE',
  LENDER_DATABASE_DASHBOARD: 'LENDER_DATABASE_DASHBOARD',
  LENDER_DATABASE_CONTACTS: 'LENDER_DATABASE_CONTACTS',
  CAPITALIZED_INTEREST_REPORT: 'CAPITALIZED_INTEREST_REPORT',
  AMORTIZING_ACCRUAL_REPORT: 'AMORTIZING_ACCRUAL_REPORT',
  ASSET_REPORTS: 'ASSET_REPORTS',
  DATA_TAPE_REPORT: 'DATA_TAPE_REPORT',
  TRIAL_BALANCE_REPORT: 'TRIAL_BALANCE_REPORT',
  REMITTANCE_REPORT: 'REMITTANCE_REPORT',
  DELINQUENCY_REPORT: 'DELINQUENCY REPORT',
  PORTFOLIO_CASHFLOW_REPORT: 'PORTFOLIO_CASHFLOW_REPORT',
  PEQ_CASHFLOW_REPORT: 'PEQ_CASHFLOW_REPORT',
  DISB_OPEN_BALANCE_REPORT: 'DISB_OPEN_BALANCE_REPORT',
  DBRS_REPORT: 'DBRS_REPORT',
  AT_RISK_REPORT: 'AT_RISK_REPORT',
  PORTFOLIO_TRANCHE: 'PORTFOLIO_TRANCHE',
  PORTFOLIO_SWEEP_REPORT: 'PORTFOLIO_SWEEP_REPORT',
  CAPI_STATEMENT: 'CAPI_STATEMENT',
  PRICING: 'PRICING',
  PORTFOLIO_DETAIL: 'PORTFOLIO_DETAIL',
  PAYMENT_HISTORY: 'PAYMENT_HISTORY',
  PREPAYMENT: 'PREPAYMENT',
  PREPAYMENT_DETAIL_REPORT: 'PREPAYMENT_DETAIL_REPORT',
  PREPAYMENT_SUMMARY_REPORT: 'PREPAYMENT_SUMMARY_REPORT',
  ECM: 'ECM',
  SCOPE_OF_SUMMARY: 'SCOPE_OF_SUMMARY',
  SCOPE_OF_WORK: 'SCOPE_OF_WORK',
  PEQ_COMMITMENT_EXPORT: 'PEQ_COMMITMENT_EXPORT',
  PE_TERM_SHEET_EXPORT: 'PE_TERM_SHEET_EXPORT',
  PROJECT_P_AND_L: 'PROJECT_P_AND_L',
};
