import { NgModule } from "@angular/core";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

@NgModule({
    declarations: [],
    imports: [
      BsDatepickerModule.forRoot()
    ],
    exports: [
      BsDatepickerModule
    ],
  })
  export class NgxModule {}