import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { AuthService } from 'src/app/services';
import { RootReducerState, getAuthUser } from 'src/app/store/root-reducers';
import { ChangePasswordModalComponent } from '../../modals/change-password-modal/change-password-modal.component';
import { PaceEquityUtils } from 'src/app/utils';
import { GLOBAL } from 'src/app/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  loggedInUser: any;
  changePasswordModal: MdbModalRef<ChangePasswordModalComponent> | null = null;
  modalConfig = GLOBAL.modalConfig;
  isLoggingOut = false;

  constructor(
    private _authService: AuthService,
    private store: Store<RootReducerState>,
    private modalService: MdbModalService,
    private _paceEquityUtils: PaceEquityUtils
  ) {}

  ngOnInit() {
    // this.loggedInUser = this.paceEquityUtils.getLoggedInUser();
    this.store
      .select(getAuthUser)
      .subscribe((data: any) => (this.loggedInUser = data));
  }

  logout() {
    this.isLoggingOut = true;
    this._authService.logout(()=>{
      this.isLoggingOut=false;
    });
  }

  showChangePasswordModal() {
    const config = this._paceEquityUtils.shallowCopy(this.modalConfig);
    config.modalClass = 'modal-md modal-dialog-centered';

    this.changePasswordModal = this.modalService.open(
      ChangePasswordModalComponent,
      config
    );
  }
}
