export const MARKET_LEADER_LIST = 'MARKET_LEADER_LIST';
export const TRANSACTION_MANAGER_LIST = 'TRANSACTION_MANAGER_LIST';
export const USER_LIST = 'USER_LIST';
export const PROGRAM_LIST = 'PROGRAM_LIST';
export const SELECT_PROJECT = 'SELECT_PROJECT';
export const UPDATE_PHASE_PROJECT = 'UPDATE_PHASE_PROJECT';
export const RESET_GLOBAL_ACTION = 'RESET_GLOBAL_ACTION';
export const PROPERTY_TYPE_LIST='PROPERTY_TYPE_LIST'

export class MarketLeaderAction {
  readonly type = MARKET_LEADER_LIST;
  constructor(public payload?: any) {}
}

export class TransactionManagerAction {
  readonly type = TRANSACTION_MANAGER_LIST;
  constructor(public payload?: any) {}
}

export class UserAction {
  readonly type = USER_LIST;
  constructor(public payload?: any) {}
}

export class ProgramAction {
  readonly type = PROGRAM_LIST;
  constructor(public payload?: any) {}
}

export class SelectedProjectAction {
  readonly type = SELECT_PROJECT;
  constructor(public payload?: any) {}
}

export class UpdatePhaseProject {
  readonly type = UPDATE_PHASE_PROJECT;
  constructor(public payload?: any) {}
}

export class PropertyTypeAction{
  readonly type=PROPERTY_TYPE_LIST;
  constructor(public payload?:any){}
}

export class ResetGlobalAction {
  readonly type = RESET_GLOBAL_ACTION;
}

export type Actions =
  | MarketLeaderAction
  | TransactionManagerAction
  | UserAction
  | ProgramAction
  | SelectedProjectAction
  | PropertyTypeAction
  | ResetGlobalAction
  ;
