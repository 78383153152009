import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, debounceTime, map } from 'rxjs';
import { ColumnFilterData } from 'src/app/models/model.columFilterData';
import { PaceEquityUtils } from 'src/app/utils';

@Component({
  selector: 'app-advance-column-filter',
  templateUrl: './advance-column-filter.component.html',
  styleUrls: ['./advance-column-filter.component.scss'],
})
export class AdvanceColumnFilterComponent {
  searchKeyword = new Subject<string>();
  searchKey = '';
  selectedOptions: any;
  selectedOperations: any;
  rangeForm: FormGroup;
  rangeValue: any;
  displayOperatorInput: boolean = false;
  rangeGap: number = 1;
  min: any = 1;
  max: any = 10;
  options: any[] = [];
  @Input() header: any;
  @Input() disabled: boolean = false;
  @Input() isDataLoaded: boolean = false;
  @Input() codeName: string = '';
  @Input() columnFilterData: ColumnFilterData = new ColumnFilterData();
  @Output() emitSearchKeyword = new EventEmitter<any>();

  constructor(private _paceEquityUtils: PaceEquityUtils) {
    this.searchKeyword
      .pipe(
        map((event) => event),
        debounceTime(300)
      )
      .subscribe((value) => {
        this.searchKey = value;
        return this.filter(value);
      });
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.rangeForm = new FormGroup({
      range: new FormControl([this.min, this.max]),
    });
  }

  ngOnChanges() {
    // this.columnFilterData = this._paceEquityUtils.getStorage(
    //   'columnFilterData',
    //   'session'
    // );
    if (this.columnFilterData && this.isDataLoaded) this.mapColumnFilter(this.header);
    // if (this.header?.name === 'rucaCode') {
    //   this.min = Math.min(
    //     ...this.header?.advancedSearch?.options?.map((item: any) => item.value)
    //   );
    //   this.max = Math.max(
    //     ...this.header?.advancedSearch?.options?.map((item: any) => item.value)
    //   );
    //   this.setForm();
    // }
  }

  clear() {
    this.filter(this.searchKeyword.next(''));
  }
  applyOperator(operation: any) {
    this.displayOperatorInput = true;
    // this.rangeValue = null;
    this.header.advancedSearch.operations =
      this.header?.advancedSearch?.operations?.map((op: any) => {
        if (op?.operand == operation?.operand) {
          this.selectedOperations = {
            ...op,
            isSelected: true,
          };
          return this.selectedOperations;
        } else return { ...op, isSelected: false };
      });
    if (operation?.operand === 'x-y') {
      this.min = Math.min(
        ...this.header?.advancedSearch?.options?.map((item: any) => item.value)
      );
      this.max = Math.max(
        ...this.header?.advancedSearch?.options?.map((item: any) => item.value)
      );
      this.rangeValue = this.header.advancedSearch['selected']?.length
        ? this.header.advancedSearch['selected'][0] +
          '-' +
          this.header.advancedSearch['selected'][1]
        : this.min + '-' + this.max;
        
      this.rangeForm.patchValue({
        range: [this.min, this.max],
      });
    }
    this.filter(this.header.advancedSearch['selected']);
  }

  handleSelected() {
    return this.header?.advancedSearch?.operations?.find(
      (x: any) => x.isSelected
    );
  }

  filter(value: any) {
    const obj = {
      // distinctName: this.header?.distinctName,
      ...this.header,
      value: value ?? null,
      // type: this.header?.type,
    };
    this.emitSearchKeyword.emit(obj);
  }

  reset() {
    this.header.advancedSearch.selected = [];
    this.header.advancedSearch.operations =
      this.header?.advancedSearch?.operations?.map((op: any) => {
        return { ...op, isSelected: false };
      });
    this.rangeForm.patchValue({
      range: [this.min, this.max],
    });
    if (this.selectedOperations?.operand === 'x-y') {
      // this.rangeValue = null;
      this.displayOperatorInput = false;
    }
    this.selectedOperations = null;
    this.filter(this.searchKeyword.next(''));
  }

  changeValue(event: any) {
    const [first, second] = event;
    if (this.selectedOperations?.operand === 'x-y') {
      const obj = {
        ...this.header,
        value: [first, second],
      };
      this.rangeValue = first + ' - ' + second;
      this.emitSearchKeyword.emit(obj);
    } else if (
      this.selectedOperations?.operand === '>' ||
      this.selectedOperations?.operand === '<'
    ) {
      const obj = {
        ...this.header,
        value: event,
      };
      this.emitSearchKeyword.emit(obj);
    } else if (!event) {
      this.filter(this.searchKeyword.next(''));
    }
  }

  onRangeValueChange() {
    const [first, second] = this.rangeForm.value.range;
    this.rangeForm.controls['range'].disable({ emitEvent: false });
    if (first > second) {
      this.rangeForm.value.range[0] = second;
      this.rangeForm.value.range[1] = first;
    } else if (first == second) {
      if (first + this.rangeGap < 10) {
        this.rangeForm.value.range[0] = first;
        this.rangeForm.value.range[1] = first + this.rangeGap;
      }
      else  {
        this.rangeForm.value.range[0] = second - this.rangeGap;
        this.rangeForm.value.range[1] = second;
      }
    }
    this.rangeForm.patchValue({
      range: [this.rangeForm.value.range[0], this.rangeForm.value.range[1]],
    });
    this.changeValue(this.rangeForm.value.range);
  }

  mapColumnFilter(header: any) {
    Object.entries(this.columnFilterData).map(([key, val]) => {
      if (header?.name === key) {
        if (key === 'rucaCode' && val) {
          this.mapRucaCode(key);
        } else {
          this.header.advancedSearch['selected'] = val;
          this.filter(this.header.advancedSearch['selected']);
        }
      }
    });
  }

  mapRucaCode(key: any) {
    const { less_than, greater_than, is_equal } =
      this.columnFilterData[key as keyof ColumnFilterData];

    if (this.header?.name === 'rucaCode') {
      this.min = Math.min(
        ...this.header?.advancedSearch?.options?.map((item: any) => item.value)
      );
      this.max = Math.max(
        ...this.header?.advancedSearch?.options?.map((item: any) => item.value)
      );
      this.setForm();
    }

    if (greater_than && less_than && !is_equal) {
      // return value > greater_than && value < less_than;
      this.header.advancedSearch['selected'] = [greater_than, less_than];
      this.min =
        less_than ??
        Math.min(
          ...this.header?.advancedSearch?.options?.map(
            (item: any) => item.value
          )
        );
      this.max =
        greater_than ??
        Math.max(
          ...this.header?.advancedSearch?.options?.map(
            (item: any) => item.value
          )
        );
      this.setForm();
      const operation = this.header.advancedSearch['operations'].find(
        (x: any) => x.operand === 'x-y'
      );
      this.applyOperator(operation);
    }
    // check greater than
    else if (greater_than && !less_than && !is_equal) {
      this.header.advancedSearch['selected'] = greater_than;
      const operation = this.header.advancedSearch['operations'].find(
        (x: any) => x.operand === '>'
      );
      this.applyOperator(operation);
    }
    // check less than
    else if (less_than && !greater_than && !is_equal) {
      this.header.advancedSearch['selected'] = less_than;
      const operation = this.header.advancedSearch['operations'].find(
        (x: any) => x.operand === '<'
      );
      this.applyOperator(operation);
    }
    // check is equal
    else {
      this.header.advancedSearch['selected'] = is_equal;
      this.filter(this.header.advancedSearch['selected']);
    }
  }

  splitRange() {
    return this.rangeValue?.length
      ? this.rangeValue.split('-').map((x: any) => Number(x))
      : [];
  }
}
