import { Injectable } from '@angular/core';
import { longDateOption } from './moment.util';
import { PaceEquityUtils } from './pace-equity.utils';
import { PEQCommitmentExport } from '../models/model.peqCommitmentExport';
import { PETermSheetExport } from '../models';
import { ToasterService } from '../services';
@Injectable({
  providedIn: 'root',
})
export class ExportUtils {
  constructor(
    private toasterService: ToasterService,
    public paceEquityUtils: PaceEquityUtils,
  ) {}

  // To manipulate the value
  public getValue(key: any, value: any) {
    if (
      key === 'assessmentAnnualTotal' ||
      key === 'piPaymentAnnualTotal' ||
      key === 'totalAmountFinanced' ||
      key === 'seniorDebtOutstandingBalance' ||
      key === 'asIsAppraisalValue' ||
      key === 'directCost' ||
      key === 'paceAssessment' ||
      key === 'sponserAmount' ||
      key === 'brokerFee' ||
      key === 'investorAmount' ||
      key === 'legalClosingExpenses' ||
      key === 'brokerFee' ||
      key === 'fixedCosts' ||
      key === 'projectDevelopmentCost' ||
      key === 'asCompletedAppraisedValue' ||
      key === 'asStabilizedAppraisalValue' ||
      key === 'firstPaymentOveride' ||
      key === 'landContribution' ||
      key === 'seniorMortgage' ||
      key === 'cashEquity' ||
      key === 'totalProjectDevelopmentBudget' ||
      key === 'currentTotalDebtPlusPACEOutstanding' ||
      key === 'total' ||
      key === 'fixedAmount' ||
      key === 'subordinateDebtOutstandingBalance' ||
      key === 'min' ||
      key === 'max' ||
      key === 'investorAmount' ||
      key === 'loanAmount' ||
      key === 'valueAtCompletion' ||
      key === 'financeAndFacilityCost' ||
      key === 'capOfPercentage' ||
      key === 'legalAndClosingExpenses' ||
      key === 'valueAsIs' ||
      key === 'sponsorAmount' ||
      key === 'annualAssessment' ||
      key === 'valueAtStabilization' ||
      key === 'depositsReceived' ||
      key === 'brokerFee' ||
      key === 'financeFacilityCost' ||
      key === 'totalDirectCostsFinanced' ||
      key === 'totalFees' ||
      key === 'capitalizedInterest' ||
      key === 'totalAssessment' ||
      key === 'totalAnnualAssessmentPaymentAmount' ||
      key === 'programFee' ||
      key === 'municipalFee' ||
      key === 'peServicingFee' ||
      key === 'otherFees' ||
      key === 'peServicingFeePerYr' ||
      key === 'overallFinancedAmount' ||
      key === 'backlogTotalAmountFinanced' ||
      key === 'programAdministrationFeesPerYr' ||
      key === 'closedTotalAmountFinanced' ||
      key.includes('constructionLoan') ||
      key.includes('USDALoan') ||
      key.includes('SBALoan') ||
      key.includes('subordinatedDebt') ||
      key.includes('mezzanineDebt') ||
      key.includes('otherDebt') ||
      key.includes('taxCreditEquity') ||
      key.includes('sponsorEquity') ||
      key.includes('investorEquity') ||
      key.includes('deferredDeveloperFee') ||
      key.includes('landEquity') ||
      key.includes('otherEquity') ||
      key.includes('PACEEquity') ||
      key === 'amount' ||
      key === 'minimumAmount' ||
      key === 'maximumAmount' ||
      key === 'annualMortgageDebtService' ||
      key === 'originalFace'
    ) {
      return this.formatMoney(key, value);
    } else if (
      key === 'servicingRate' ||
      key === 'percentageBegPrincipalBalanceFee' ||
      key === 'percentageOfPIPayment' ||
      key === 'combinedStabilizedLTV' ||
      key === 'percentageAmtFunded' ||
      key === 'yieldToWorst' ||
      key === 'stabilizedLTV' ||
      key === 'percentageOfCost' ||
      key === 'rate' ||
      key === 'grossCouponRate' ||
      key === 'statedAssessmentRate' ||
      key === 'assetRate' ||
      key === 'lessPEServicingFee' ||
      key === 'netRate' ||
      key === 'purchasePricePercent' ||
      key === 'percentageDirectCostAndFees' ||
      key === 'percentageDirectCostAndFeesWithCapI' ||
      key === 'percentageDirectCost' ||
      key === 'rateDiff' ||
      key === 'cirrusRate' ||
      key === 'cirrusMinimumRate' ||
      key === 'cirrusRateCap' ||
      key === 'minRateDiff' ||
      key === 'rateCapDiff' ||
      key === 'standardRate' ||
      key === 'standardMinimumRate' ||
      key === 'standardRateCap' ||
      key === 'prepaymentPenaltyRate' ||
      key === 'standardRateCap'
    ) {
      value = value || value === 0 ? Number(value).toFixed(2) : '';
      if (value) {
        return value + ' %';
      } else {
        return '';
      }
      // return value && value != null ? value + " %" : '';
    } else if (key === 'term') {
      return value ? value + ' (months)' : '';
    } else if (key === 'amortizationTerm') {
      return value ? value + ' (years)' : '';
    } else if (key == 'buildingSize') {
      value = Number(value);
      return value ? value.toLocaleString() + ' SF' : '';
    } else if (key == 'programFeeInRate') {
      return value ? value : '0';
    } else if (
      key == 'lessPACEAdminFee' ||
      key == 'certificationDate' ||
      key == 'other'
    ) {
      return value ? value : 'N/A';
    } else if (key == 'percentagePiPayment') {
      return value ? value + '% of P&I Payment' : '';
    } else {
      return value || value == 0 ? value : '';
    }
  }

  // Format money to USD format
  public formatMoney(key: any, number: number) {
    if (number || number === 0) {
      const numberPart = String(number).split(' ');
      const bracketPart = numberPart[1] ? numberPart[1] : '';
      let formattedNumber;
      if (key != 'depositsReceived') {
        const numberBeingFormatted = Number(numberPart[0]);
        if (numberBeingFormatted || numberBeingFormatted === 0) {
          formattedNumber = numberBeingFormatted.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        } else {
          formattedNumber = '';
        }
      } else {
        let depositsFormatted: any[] = [];
        if (numberPart[0] && numberPart[0] != '') {
          const depositSplitted = numberPart[0].split(',');
          depositSplitted.forEach((deposit) => {
            const numberBeingFormatted = Number(deposit);
            const formattedData = numberBeingFormatted.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            });
            depositsFormatted.push(formattedData);
          });
          formattedNumber = depositsFormatted.join(', ');
        }
      }
      let formattedSecondarydata: any = '';
      if (bracketPart || bracketPart == '0') {
        if (key == 'valueAtCompletion' || key == 'valueAtStabilization') {
          formattedSecondarydata = ` (${bracketPart})`;
        } else {
          let numPart = Number(bracketPart).toFixed(2);
          formattedSecondarydata = ` (${numPart}%)`;
        }
        return formattedNumber + formattedSecondarydata;
      } else {
        return formattedNumber;
      }
    } else {
      return '';
    }
  }

  // Add $, % or any other marker for value
  public getText(key: string, value: string, type = '') {
    switch (type) {
      case 'program': {
        if (key === 'paceBondTrustees') {
          return 'PACE Bond Trustees';
        } else if (key === 'percentageAddedToInterestRate') {
          return '% Added to Interest';
        } else if (key === 'percentageBegPrincipalBalanceFee') {
          return '% of Begin Balance';
        } else if (key === 'percentageAmtFunded') {
          return '% of Amount Funded';
        } else if (key === 'percentagePiPayment') {
          return '% of P&I';
        } else if (key === 'firstPaymentOveride') {
          return '1st Payment Override';
        } else if (key === 'isPercentageOfAnnualAssessment') {
          return 'Include PEQ Admin Fee?';
        } else if (key === 'isAdminInterest') {
          return 'Does Admin interest apply during cap I period?';
        } else if (key === 'isSinglePrincipalPayment') {
          return 'Single Principal Payment?';
        } else if (key === 'percentageDirectCostAndFees') {
          return '% of Direct Costs with Fees';
        } else if (key === 'percentageDirectCostAndFeesWithCapI') {
          return '% of Direct Cost with Fees plus CapI';
        } else if (key === 'percentageDirectCost') {
          return '% of Direct Cost';
        } else if (key === 'capOfPercentage') {
          return 'Cap of %';
        } else if (key === 'billingAndCollectionDefinition') {
          return 'Program Billing Notes';
        }
        break;
      }
      default: {
        if (key === 'buildingOrAssessmentOwnershipChangeHistory') {
          return 'Building / Assessment Ownership Change History';
        } else if (key === 'sponserContactInfo') {
          return `Sponsor / Owner POC<br />Address<br />Phone<br />Email`;
        } else if (
          key ===
          'taxBillIssueDateOrDirectBillDateIfDirectBillListPartyToSendBilling'
        ) {
          return 'Tax Bill Issue Date or Direct Bill Date. If Direct Bill List Party to Send Billing';
        } else if (key === 'peServicingFee') {
          return 'PE Servicing Fee';
        } else if (key === 'billingProcessAndFlowOfFundsDescription') {
          return 'Billing Process and Flow of Funds Description';
        } else if (key === 'programFeeOutsideOfRate') {
          return `Program Fee`;
        } else if (key === 'termYrs') {
          return `Amortization Term (years)`;
        } else if (key === 'term') {
          return `Term (mo.)`;
        } else if (key === 'stabilizedLTV') {
          return 'Stabilized LTV (PACE/Total)';
        } else if (key === 'combinedStabilizedLTV') {
          return 'Combined Stabilized LTV ((PACE+Mortgage)/Total)';
        } else if (key === 'cirrusMinimumRate') {
          return 'CIRRUS Minimum Rate';
        } else if (key === 'cirrusRateCap') {
          return 'CIRRUS Rate Cap';
        } else if (key === 'cirrusSpread') {
          return 'CIRRUS Spread';
        } else if (key === 'cirrusRate') {
          return 'CIRRUS Rate';
        } else if (key === 'minRateDiff') {
          return 'Minimum Rate Difference';
        } else if (key === 'rateCapDiff') {
          return 'Rate Cap Difference';
        } else if (key === 'spreadDiff') {
          return 'Spread Difference';
        } else if (key === 'rateDiff') {
          return 'Rate Difference';
        } else if (key === 'percentageDirectCostAndFees') {
          return '% of Direct Costs with Fees';
        } else if (key === 'percentageDirectCostAndFeesWithCapI') {
          return '% of Direct Cost with Fees plus CapI';
        } else if (key === 'percentageDirectCost') {
          return '% of Direct Cost';
        }
      }
    }

    return this.paceEquityUtils.capitalizeFirstLetter(key);
  }

  public formatNumberToFixed(number = 0, precision = 2) {
    return Number(number?.toFixed(precision) ?? 0);
  }


  /** PE COMMITMENT/TERM SHEET EXPORT UTILS */
  public checkPricingScenario(model: PETermSheetExport | PEQCommitmentExport) {
    if (model.selectedAssessment.assessmentPricing) {
      const {
        pricingScenario,
        standardRate,
        standardSpread,
        cirrusRate,
        cirrusSpread,
      } = model.selectedAssessment?.assessmentPricing;
      if (!pricingScenario) {
        this.toasterService.warning('No pricing scenario selected.');
        return false;
      } else {
        if (model.interestRateType == 'both') {
          if (
            !standardRate ||
            !standardSpread ||
            !cirrusSpread ||
            !cirrusRate
          ) {
            this.toasterService.warning(
              `Interest rates/spread are not available for both Standard & CIRRUS.`
            );
            return false;
          }
          return true;
        } else if (model.interestRateType == 'standard') {
          if (!standardRate || !standardSpread) {
            this.toasterService.warning(
              `Interest rates/spread not available for selected interest type.`
            );
            return false;
          }
          return true;
        } else if (model.interestRateType == 'cirrus') {
          if (!cirrusRate || !cirrusSpread) {
            this.toasterService.warning(
              `Interest rates/spread not available for selected interest type.`
            );
            return false;
          }
          return true;
        }
        return false;
      }
    } else {
      this.toasterService.warning(
        'Pricing has not been committed for selected assessment.'
      );
      return false;
    }
  }
  /** PE COMMITMENT/TERM SHEET EXPORT UTILS */
}
