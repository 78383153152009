import { Directive } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appPasswordMatch]',
})
export class PasswordValidationDirective {
  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('password')?.value; // to get value in input tag
    const confirmPassword = AC.get('confirmPassword')?.value; // to get value in input tag
    if (password != confirmPassword) {
      AC.get('confirmPassword')?.setErrors({ MatchPassword: true });
    } else {
      // return null
    }
  }
}
