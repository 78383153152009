import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants/api.constant';
import { Observable, catchError, map, throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { PaceEquityUtils, momentIsSameOrBefore } from '../utils';
import { RootReducerState } from '../store/root-reducers';
import { Store } from '@ngrx/store';
import { LogoutAction } from '../store/auth/actions/auth-actions';
import { ResetGlobalAction } from '../store/global/actions';
import { ResetPhase } from '../store/phases/action/phase-action';
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedInUser: any;
  permissionAccess: any;
  hasPermissionAccess: boolean = false;
  constructor(
    private _router: Router,
    private http: HttpClientService,
    private _store: Store<RootReducerState>,
    public _paceEquityUtils: PaceEquityUtils
  ) {}

  public isAuthenticated(): boolean {
    this._paceEquityUtils.verifyBrowserId();
    this.loggedInUser = this._paceEquityUtils.getLoggedInUser();
    if (this.loggedInUser) {
      const isExpired = momentIsSameOrBefore(
        this.loggedInUser.expiryDate,
        new Date()
      );
      if (isExpired) {
        this._paceEquityUtils.removeLoggedInUser();
        this._router.navigate(['/login']);
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  public refreshToken(): Observable<any> {
    // Implement your refresh token logic here, making the API call and returning an Observable
    return this.http
      .get(`${APICONSTANTS.AUTH}${APICONSTANTS.REFRESH_TOKEN}`, {})
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  logout(_event: any = () => {}) {
    let loggedInUser = this._paceEquityUtils.getLoggedInUser();
    return this.http
      .post(`${APICONSTANTS.AUTH}${APICONSTANTS.LOGOUT}`, {
        deviceId: loggedInUser?.deviceId,
        userId: loggedInUser?.userId,
      })
      .subscribe({
        next: (res: any) => {
          this._store.dispatch(new ResetPhase());
          this._store.dispatch(new LogoutAction());
          this._store.dispatch(new ResetGlobalAction());
          this._paceEquityUtils.removeLoggedInUser();
          sessionStorage.clear();
          this._router.navigate(['/login']);
          return true;
        },
      });
  }

  login(credentials: any) {
    return this.http
      .post(`${APICONSTANTS.AUTH}${APICONSTANTS.LOGIN}`, credentials)
      .pipe(
        map((response: any) => {
          if (response && response.success && response.data.token) {
            const { token, permission } = response.data;
            const decodedToken = helper.decodeToken(token);
            decodedToken.token = token;
            decodedToken.permission = permission;
            return decodedToken;
          } else {
            return response;
          }
        })
      );
  }

  verifyAuthUser() {
    return this.http.get(`${APICONSTANTS.AUTH}${APICONSTANTS.AUTH_USER}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  // logout() {
  //   this._paceEquityUtils.removeLoggedInUser();
  //   sessionStorage.clear();
  //   this._router.navigate(['/login']);
  // }

  forgotPassword(credentials: any) {
    return this.http
      .post(`${APICONSTANTS.AUTH}${APICONSTANTS.FORGOT_PASSWORD}`, credentials)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  setPassword(credentials: any) {
    return this.http
      .post(`${APICONSTANTS.AUTH}${APICONSTANTS.SET_PASSWORD}`, credentials)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  resetPassword(credentials: any) {
    return this.http
      .post(`${APICONSTANTS.AUTH}${APICONSTANTS.RESET_PASSWORD}`, credentials)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  verifyCode(code: any) {
    return this.http
      .post(`${APICONSTANTS.AUTH}${APICONSTANTS.VERIFY_CODE}`, code)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  verifyMFACode(body: any) {
    return this.http
      .post(`${APICONSTANTS.AUTH}${APICONSTANTS.VERIFY_MFA_CODE}`, body)
      .pipe(
        map((response: any) => {
          if (response && response.success && response.data.token) {
            const { token, permission } = response.data;
            const decodedToken = helper.decodeToken(token);
            decodedToken.token = token;
            decodedToken.permission = permission;
            return decodedToken;
          } else {
            return response;
          }
        })
      );
  }

  resendMFACode(queryParams = {}) {
    return this.http
      .get(`${APICONSTANTS.AUTH}${APICONSTANTS.RESEND_MFA_CODE}`, queryParams)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  changePassword(credentials: any) {
    return this.http
      .post(`${APICONSTANTS.AUTH}${APICONSTANTS.CHANGE_PASSWORD}`, credentials)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public isRoleAuthorized(permission: any): boolean {
    this.hasPermissionAccess = false;
    this.loggedInUser = this._paceEquityUtils.getLoggedInUser();
    this.permissionAccess = this._paceEquityUtils.getPermissionAccess();
    if (permission && permission == 'CRE_CAPITAL_DATABASE')
      permission = 'LENDER_DATABASE';
    if (this.loggedInUser && this.permissionAccess) {
      permission = permission == 'ASSET' ? 'PROJECT' : permission;
      let foundPermission = this.loggedInUser.permission.find(
        (x: any) => x.permission?.code === permission
      );
      if (foundPermission) {
        this.checkPermissionAccess(permission, this.permissionAccess);
        if (!foundPermission.accessType && !this.hasPermissionAccess) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkPermissionAccess(permission: any, permissionAccess: any) {
    if (
      permission == 'PROJECT' &&
      (permissionAccess.hasProject || permissionAccess.hasPortfolio)
    ) {
      this.hasPermissionAccess = true;
    } else if (permission == 'ASSET' && permissionAccess.hasProject) {
      this.hasPermissionAccess = true;
    } else if (permission == 'PORTFOLIO' && permissionAccess.hasPortfolio) {
      this.hasPermissionAccess = true;
    } else if (
      permission == 'DOCUMENT_LIBRARY' &&
      permissionAccess.hasDocument
    ) {
      this.hasPermissionAccess = true;
    }
  }
}
