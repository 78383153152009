import {Injectable} from '@angular/core';
import {APICONSTANTS} from '../constants';
import {map} from 'rxjs/operators';
import {HttpClientService} from './http-client.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClientService) {}

  fetchDashboardLayout() {
    return this.http.get(`${APICONSTANTS.DASHBOARD_LAYOUT}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  
  fetchDashboardLayoutDataById(layoutId: number) {
    return this.http.get(`${APICONSTANTS.DASHBOARD_LAYOUT}/${layoutId}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  fetchDashboardLayoutById(data: any, layoutId: number = -1) {
    return this.http.post(`${APICONSTANTS.DASHBOARD_LAYOUT}/${layoutId}/data`, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchDashboardLayoutFields() {
    return this.http.get(`${APICONSTANTS.DASHBOARD_LAYOUT}/fields`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  fetchDashboardLayoutData(body: any) {
    return this.http.post(`${APICONSTANTS.DASHBOARD_LAYOUT}/data`, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveLayoutFields(body: any) {
    return this.http.post(`${APICONSTANTS.DASHBOARD_LAYOUT}`, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  saveLayoutFilter(id: number, body: any) {
    return this.http.patch(`${APICONSTANTS.DASHBOARD_LAYOUT}/${id}/filter-data`, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  deleteLayout(layoutId: number) {
    return this.http.remove(`${APICONSTANTS.DASHBOARD_LAYOUT}/${layoutId}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  // Update data in dashboard
  updateDashboardData(body: any) {
    return this.http.put(`${APICONSTANTS.DASHBOARD_LAYOUT}/project`, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
