export const PE_COMMITMENT_EXPORT_PHASE = [
  // '01 Prospect',
  '02 Qualify',
  '02 Gather Information',
  '03 Term Sheet',
  '04 Commitment Presented - Flat',
  '05 Commitment Presented',
];

export const PE_TERM_SHEET_EXPORT_PHASE = ['03 Term Sheet'];
