import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PaceEquityUtils } from '../utils/pace-equity.utils';
import { isArray } from 'lodash';

const API_BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  constructor(
    private http: HttpClient,
    private paceEquityUtils: PaceEquityUtils
  ) {}
  createHeader() {
    let headers = new HttpHeaders();
    return headers;
  }

  getQueryParams(queryParams = {}) {
    let params = new HttpParams();
    Object.entries(queryParams).map(([key, value]: any) => {
      if (isArray(value)) {
        params = params.append(key, JSON.stringify(value));
      } else {
        params = params.append(key, value);
      }
    });
    return params;
  }

  get(url: string, params = {}) {
    return this.http.get(API_BASE_URL + url, {
      // headers: this.createHeader(),
      params: this.getQueryParams(params),
    });
  }
  post(url: string, data: any, params = {}) {
    return this.http.post(API_BASE_URL + url, data, {
      headers: this.createHeader(),
      params: params,
    });
  }

  put(url: string, data: any, params = {}) {
    return this.http.put(API_BASE_URL + url, data, {
      // headers: this.createHeader(),
      params: params,
    });
  }

  patch(url: string, data: any, params = {}) {
    return this.http.patch(API_BASE_URL + url, data, {
      // headers: this.createHeader(),
      params: params,
    });
  }

  delete(url: string, data: any, params = {}) {
    return this.http.put(API_BASE_URL + url, data, {
      // headers: this.createHeader(),
      params: params,
    });
  }

  remove(url: string, params = {}) {
    return this.http.delete(API_BASE_URL + url, {
      // headers: this.createHeader(),
      params: params,
    });
  }

  documentGet(url: string, data: any) {
    return this.http.post(API_BASE_URL + url, data, {
      headers: this.createHeader(),
      responseType: 'arraybuffer',
    });
  }
}
