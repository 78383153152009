import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbSelectModule } from 'mdb-angular-ui-kit/select';
import { MdbDatepickerModule } from 'mdb-angular-ui-kit/datepicker';
import { MdbAutocompleteModule } from 'mdb-angular-ui-kit/autocomplete';
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbNotificationModule } from 'mdb-angular-ui-kit/notification';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbSidenavModule } from 'mdb-angular-ui-kit/sidenav';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbLoadingModule } from 'mdb-angular-ui-kit/loading';
import { MdbInputMaskModule } from 'mdb-angular-input-mask';
import { MdbDragAndDropModule } from 'mdb-angular-drag-and-drop';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbMultiRangeModule } from 'mdb-angular-ui-kit/multi-range';
import { MdbFileUploadModule } from 'mdb-angular-file-upload';
import { MdbWysiwygModule } from 'mdb-angular-wysiwyg';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MdbDropdownModule,
    MdbCollapseModule,
    MdbSelectModule,
    MdbDatepickerModule,
    MdbAutocompleteModule,
    MdbAccordionModule,
    MdbNotificationModule,
    MdbModalModule,
    MdbTooltipModule,
    MdbSidenavModule,
    MdbCheckboxModule,
    MdbFormsModule,
    MdbTabsModule,
    MdbLoadingModule,
    MdbInputMaskModule,
    MdbDragAndDropModule,
    MdbPopoverModule,
    MdbMultiRangeModule,
    MdbFileUploadModule,
    MdbWysiwygModule
  ],
  exports: [
    CommonModule,
    MdbDropdownModule,
    MdbCollapseModule,
    MdbSelectModule,
    MdbDatepickerModule,
    MdbAutocompleteModule,
    MdbAccordionModule,
    MdbNotificationModule,
    MdbModalModule,
    MdbTooltipModule,
    MdbSidenavModule,
    MdbCheckboxModule,
    MdbFormsModule,
    MdbTabsModule,
    MdbLoadingModule,
    MdbInputMaskModule,
    MdbDragAndDropModule,
    MdbPopoverModule,
    MdbMultiRangeModule,
    MdbFileUploadModule,
    MdbWysiwygModule
  ],
})
export class MDBAngularModule {}
