<div
  class="toast show fade" [ngClass]="severity"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
>
  <div class="toast-header" [ngClass]="severity">
    <i [ngClass]="icon"></i>
    <strong class="me-auto">{{summary}}</strong>
    <!-- <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="notificationRef?.close()"
    ></button> -->
  </div>
  <div class="toast-body">{{detail}}</div>
</div>
