import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AssessmentUtils {
  legalAndClosingExpensesOverriddenMessage =
    'Please provide a reason for updating the Legal & Closing Expenses amount';
  constructor() {}

  public setSelectedProgramDataForAssessment = (
    selectedProgram: any,
    {
      assessmentDate = {},
      assessmentAdministrationFee = {},
      assessmentClosingAdminFees = [],
    }: any = {}
  ) => {
    const assessment = {
      paymentsPerYear: selectedProgram.paymentsPerYear,
      firstPaymentMonthDay: selectedProgram.firstTaxPaymentMonthDay,
      secondPaymentMonthDay: selectedProgram.secondTaxPaymentMonthDay,
      firstSecuredPaymentMonthDay: selectedProgram.firstSecuredPaymentMonthDay,
      secondSecuredPaymentMonthDay:
        selectedProgram.secondSecuredPaymentMonthDay,
      isSinglePrincipalPayment: selectedProgram.isSinglePrincipalPayment,
      oneTimeFeeDescription: selectedProgram.oneTimeFeeDescription,
      assessmentDate: {
        ...assessmentDate,
        amortizationStartDate: selectedProgram.amortizationStartMonthDay,
      },
      assessmentAdministrationFee: {
        ...assessmentAdministrationFee,
        percentageAddedToInterestRate:
          selectedProgram.percentageAddedToInterestRate,
        percentageBegPrincipalBalanceFee:
          selectedProgram.percentageBegPrincipalBalanceFee,
        percentageAmtFunded: selectedProgram.percentageAmtFunded,
        percentagePiPayment: selectedProgram.percentagePiPayment,
        isPercentageOfAnnualAssessment:
          selectedProgram.isPercentageOfAnnualAssessment,
        fixedAmount: selectedProgram.fixedAmount,
        firstPaymentOveride: selectedProgram.firstPaymentOveride,
        min: selectedProgram.min,
        max: selectedProgram.max,
        isAdminInterest: selectedProgram.isAdminInterest,
      },
      assessmentClosingAdminFees: [
        ...assessmentClosingAdminFees.map((x: any) => {
          x.isActive = false;
          return x;
        }),
        ...selectedProgram.closingAdminFees.map((x: any) => {
          x.isActive = true;
          delete x.id;
          return x;
        }),
      ],
    };

    return assessment;
  };

  public setupAssessmentDataForEdit = (assessment: any) => {
    const editAssessmentModel = JSON.parse(JSON.stringify(assessment)); // removes binding of the variables

    let amortdDate = editAssessmentModel.assessmentDate?.amortizationStartDate;
    editAssessmentModel.assessmentDate.amortizationStartDate =
      amortdDate?.split('/').length > 2
        ? amortdDate.substring(0, amortdDate.lastIndexOf('/'))
        : amortdDate;
    editAssessmentModel.assessmentSweepDates.map((x: any) => {
      x.isValid = true;
    });
    editAssessmentModel.interestAmount = editAssessmentModel.isCapiOverride
      ? editAssessmentModel.interestAmount
      : null;
    editAssessmentModel.piPayment = editAssessmentModel.isManualPIPayment
      ? editAssessmentModel.piPayment
      : null;
    editAssessmentModel.piPaymentPublished =
      editAssessmentModel.isManualPIPaymentPublished
        ? editAssessmentModel.piPaymentPublished
        : null;
    editAssessmentModel.financedAmount =
      editAssessmentModel.isManualFinancedAmount
        ? editAssessmentModel.financedAmount
        : null;
    editAssessmentModel.assessmentDate.maturityDate =
      editAssessmentModel.isMaturityDateOverride ||
      editAssessmentModel.isManualPaymentSchedule
        ? editAssessmentModel.assessmentDate.maturityDate
        : null;
    editAssessmentModel.assessmentDate.closedDate = editAssessmentModel
      .assessmentDate.closedDate
      ? new Date(editAssessmentModel.assessmentDate.closedDate)
      : null;
    editAssessmentModel.assessmentDate.fundingDate = editAssessmentModel
      .assessmentDate.fundingDate
      ? new Date(editAssessmentModel.assessmentDate.fundingDate)
      : null;
    //If Funding Date and Closed Date both are same, then no need to display Funding Date value
    if (
      editAssessmentModel.assessmentDate.fundingDate ==
      editAssessmentModel.assessmentDate.closedDate
    )
      editAssessmentModel.assessmentDate.fundingDate = null;

    return editAssessmentModel;
  };
}
