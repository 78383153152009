import { Action, createSelector } from '@ngrx/store';
import { LOGIN_REQUEST, LOGIN_REQUEST_SUCCESS, LOGOUT_REQUEST } from '../actions/auth-actions';

export interface AuthReducerState {
  loading: boolean;
  isLoggedIn: boolean;
  authUser: any;
}

const initialState: AuthReducerState = {
  loading: false,
  isLoggedIn: false,
  authUser: null,
};

export function AuthReducer(
  state = initialState,
  action: any
): AuthReducerState {
  switch (action.type) {
    case LOGIN_REQUEST_SUCCESS:
    case LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
        isLoggedIn: true,
        authUser: action.payload,
      };
    }
    case LOGOUT_REQUEST:
      return {
        loading: false,
        isLoggedIn: false,
        authUser: null
      };
    default:
      return state;
  }
}

//selector to access AUth State
export const getLoading = (state: AuthReducerState) => state.loading;
export const getIsLoggedInStatus = (state: AuthReducerState) =>
  state.isLoggedIn;
export const getAuthUser = (state: AuthReducerState) => state.authUser;

// export const getLoggedInState = createSelector(getAuthState)
