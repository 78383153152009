export const MESSAGE_CONSTANTS = {
    UNAUTHORIZED: "User not authorized.",
    BAD_REQUEST: "Invalid payload format.",
    FORBIDDEN: "Forbidden.",
    SERVER_ERROR: "Server error. Please contact admin.",
    TIMEOUT: "Request timed out. Please contact admin.",

    // SESSION
    SESSION_EXPIRED: "Session expired.",

    INVALID_URL: "The requested url is invalid." 
}