import { Injectable } from '@angular/core';
import { PaceEquityUtils } from '../utils';
import { ExportUtils } from '../utils/export-util';
import { asBlob } from 'html-docx-js-typescript';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class DocxService {
  annualAdminFees = [
    'percentageAddedToInterestRate',
    'percentageBegPrincipalBalanceFee',
    'percentageAmtFunded',
    'percentageOfPIPayment',
    'fixedAmount',
    'firstPaymentOveride',
    'min',
    'max',
    'percentageDirectCostAndFees',
    'capOfPercentage',
    'fixedCosts',
    'oneTimeFeeDescription',
  ];

  constructor(
    private paceEquityUtils: PaceEquityUtils,
    private exportUtils: ExportUtils
  ) {}

  qmTemplate(qm: any) {
    const projectName = qm.projectName;
    let htmlString = `<!DOCTYPE html>
    <html>
      <head>
        <meta charset='utf-8'>
        <title>Title of the document</title>
        <style>
        *{
          font-family: "Times New Roman", Times, serif;
          }
          p {
            margin: 0;
          }
          table {
            border-collapse: collapse;
          }
          td,
          th {
            padding: 5px;
            border: 1px solid black;
          }
        </style>
      </head>
      <body>
        <p style='padding: 0; margin: 0;'>Date: ${
          new Date().toLocaleString().split(',')[0]
        }</p>
        <table><tr><th style='background-color: black; color: white; font-size: 18px; text-align: center; vertical-align: center; font-weight: bold;' colspan='2'><p>Qualification Matrix</p></th></tr>`;
    for (let data in qm) {
      if (this.paceEquityUtils.isObject(qm[data]) && data !== 'pricing') {
        htmlString += `<tr><td style='text-align: left; vertical-align: top; width: 30%;'><p>${this.exportUtils.getText(
          data,
          qm[data]
        )}</p></td><td style='text-align: left; width: 70%;'>`;
        for (let innerData in qm[data]) {
          if (
            this.annualAdminFees.includes(innerData) &&
            this.exportUtils.getValue(innerData, qm[data][innerData]) == ''
          ) {
          } else if (innerData === 'billDates') {
            const billDates = qm[data][innerData]
              .filter((x: any) => x.isActive)
              .map((x: any) => x.billDate)
              .join(', ');
            htmlString += `<p>${this.exportUtils.getText(
              innerData,
              qm[data][innerData]
            )}: ${billDates}  `;
          } else if (
            this.paceEquityUtils.isArrayOfObject(qm[data][innerData])
          ) {
            if (innerData == 'assessments') {
              if (qm[data][innerData]?.length !== 1) {
                htmlString += `<p>Assessments (TAF):<br />`;
                for (let arrItem of qm[data][innerData]) {
                  htmlString += `&nbsp;&nbsp;${
                    arrItem['name']
                  }: ${this.exportUtils.getValue(
                    'originalFace',
                    arrItem['originalFace']
                  )}<br />`;
                }
                htmlString += `</p>`;
              }
            } else {
              for (let arrItem of qm[data][innerData]) {
                for (let itemData in arrItem) {
                  // for criteria ranges
                  if (itemData === 'criteria' && arrItem[itemData]) {
                    htmlString += `<p>${this.exportUtils.getText(
                      itemData,
                      qm[data][innerData][itemData]
                    )}:`;
                    for (let innerItemData of arrItem[itemData].ranges) {
                      htmlString += innerItemData?.max
                        ? `<p>
                                ${
                                  innerItemData?.min === null
                                    ? 'Upto '
                                    : this.exportUtils.getValue(
                                        'min',
                                        innerItemData?.min
                                      ) + ' to'
                                }
                                ${this.exportUtils.getValue(
                                  'max',
                                  innerItemData?.max
                                )}, then
                                ${this.exportUtils.getValue(
                                  'rate',
                                  innerItemData?.rate
                                )} ${
                            innerItemData?.calculation
                              ? ' - ' +
                                this.exportUtils.getValue(
                                  'amount',
                                  innerItemData?.calculation?.value
                                )
                              : ''
                          }
                              </p>`
                        : `<p>Above ${this.exportUtils.getValue(
                            'min',
                            innerItemData?.min
                          )}, then
                                ${this.exportUtils.getValue(
                                  'rate',
                                  innerItemData?.rate
                                )} ${
                            innerItemData?.calculation
                              ? ' - ' +
                                this.exportUtils.getValue(
                                  'amount',
                                  innerItemData?.calculation?.value
                                )
                              : ''
                          }</p>
                                `;
                    }
                  } else {
                    htmlString += `<p>${this.exportUtils.getText(
                      itemData,
                      arrItem[itemData]
                    )}: ${this.exportUtils.getValue(
                      itemData,
                      arrItem[itemData]
                    )} </p>`;
                  }
                }
                htmlString += `<br />`;
              }
            }
          } else {
            htmlString += `<p>${this.exportUtils.getText(
              innerData,
              qm[data][innerData]
            )}: ${this.exportUtils.getValue(
              innerData,
              qm[data][innerData]
            )} </p>`;
          }
          if (innerData == 'billDates') {
            htmlString += `<b><p>Annual Admin Fees</p></b>`;
          }
          if (innerData == 'max') {
            htmlString += `<b><p>Closing Admin Fees</p></b>`;
          }
        }
        htmlString += `</td></tr>`;
      } else if (data === 'pricing') {
        if (qm[data] === null) {
          delete qm[data];
        } else {
          htmlString += `<tr><td style='text-align: left; vertical-align: top; width: 30%; '><p>${this.exportUtils.getText(
            data,
            qm[data]
          )}</p></td><td>`;
          // for (let innerData in qm[data]) {
          //   if (qm[data][innerData] !== null) {
          //     htmlString += `
          //       <p>${this.exportUtils.getText(
          //         innerData,
          //         qm[data][innerData]
          //       )}: ${this.exportUtils.getValue(
          //       innerData,
          //       qm[data][innerData]
          //     )}</p>`;
          //   }
          //   htmlString += '</td></tr>';
          // }

          for (let innerData in qm[data]) {
            if (qm[data][innerData] !== null) {
              let fontWeight :any
              // highlight color according to pricing Scenario
              if (qm[data]['pricingScenario'] === 'CIRRUS Low Carbon') {
                if (
                  innerData === 'cirrusRate' ||
                  innerData === 'cirrusMinimumRate' ||
                  innerData === 'cirrusRateCap' ||
                  innerData === 'cirrusSpread'
                ) {
                  fontWeight = 900;
                }
              } else if (qm[data]['pricingScenario'] === 'Standard') {
                if (
                  innerData === 'standardRate' ||
                  innerData === 'standardMinimumRate' ||
                  innerData === 'standardRateCap' ||
                  innerData === 'standardSpread'
                ) {
                  fontWeight = 900;
                }
              }

              htmlString += `<p style="font-weight: ${fontWeight}">${this.exportUtils.getText(
                innerData,
                qm[data][innerData]
              )}: ${this.exportUtils.getValue(
                innerData,
                qm[data][innerData]
              )}</p>`;
            }

            htmlString += '</td></tr>';
          }
        }
      } else {
        htmlString += `<tr><td style='text-align: left; vertical-align: top; width: 30%; ${
          data == 'projectName' ? 'font-weight: bold;' : ''
        }'><p>${this.exportUtils.getText(
          data,
          qm[data]
        )}</p></td><td style='text-align: left; width: 70%; ${
          data == 'projectName' ? 'font-weight: bold;' : ''
        }'><p>${
          qm[data] || qm[data] == 0
            ? this.exportUtils.getValue(data, qm[data])
            : ''
        }</p></td></tr>`;
      }
    }
    htmlString += `</table></body></html>`;
    this.generateDoc(htmlString, 'QM_' + projectName);
  }

  lenderContactsTemplate(contacts: any) {
    let htmlString = `<!DOCTYPE html>
    <html>
      <head>
        <meta charset='utf-8'>
        <title>Title of the document</title>
        <style>
        *{
          font-family: "Times New Roman", Times, serif;
          }
          p {
            margin: 0;
          }
          table {
            border-collapse: collapse;
          }
          td,
          th {
            padding: 5px;
            border: 1px solid black;
          }

        </style>
      </head>
      <body>
        <p style='padding: 0; margin: 0;'>Date: ${
          new Date().toLocaleString().split(',')[0]
        }</p>
        <table style='width: 100%'>`;

    for (let data of contacts) {
      for (let innerData of data.contactGroups) {
        for (let contactData of innerData?.contacts) {
          htmlString += `<tr><td style='display:flex; justify-content: space-between; flex-direction: column; text-align: left; width: 70%;'>`;
          htmlString += `<p>
            <b>${data?.name}</b>${data?.name ? '<br />' : ''}
            ${innerData.tagName} ${innerData?.tagName ? '<br />' : ''}
              ${
                contactData?.contact?.firstName
                  ? contactData?.contact?.firstName +
                    ' ' +
                    contactData?.contact?.lastName +
                    '<br />'
                  : ''
              } 
              ${
                contactData?.contact?.company
                  ? contactData?.contact?.company + '<br />'
                  : ''
              }
              ${
                contactData?.contact?.title
                  ? contactData?.contact?.title + '<br />'
                  : ''
              }
            
              ${this.getFormattedAddress(
                '',
                contactData?.contact?.companyContacts?.address?.Address
                  ?.addressline1,
                contactData?.contact?.companyContacts?.address?.Address
                  ?.addressline2
              )}${
            contactData?.contact?.companyContacts?.address?.Address
              ?.addressline1 ||
            contactData?.contact?.companyContacts?.address?.Address
              ?.addressline2
              ? ''
              : ''
          }
              ${this.getFormattedAddress(
                contactData?.contact?.companyContacts?.address?.Address?.zip,
                contactData?.contact?.companyContacts?.address?.Address?.city,
                contactData?.contact?.companyContacts?.address?.Address?.state
              )}  ${
            contactData?.contact?.companyContacts?.address?.Address?.city ||
            contactData?.contact?.companyContacts?.address?.Address?.state ||
            contactData?.contact?.companyContacts?.address?.Address?.zip
              ? '<br>'
              : ''
          }
               ${
                 contactData?.contact?.phone &&
                 contactData.contact.phone['Phone']['number']
                   ? contactData.contact.phone['Phone']['extension']
                     ? contactData.contact.phone['Phone']['number'] +
                       ' ext:' +
                       contactData.contact.phone['Phone']['extension']
                     : contactData.contact.phone['Phone']['number']
                   : contactData.contact.phone['Mobile']['number']
               } ${
            contactData.contact.phone['Phone']['number'] ||
            contactData.contact.phone['Mobile']['number']
              ? '<br />'
              : ''
          }
          </p>
          <p>${contactData?.contact?.email?.Email}</p> <br /> <br />`;
        }
      }
      htmlString += `<br>`;
      htmlString += `</td></tr>`;
    }
    htmlString += `</table></body></html>`;
    this.generateDoc(htmlString, 'Lender Contacts');
  }

  getFormattedAddress(zip: string, ...addressObj: any) {
    return zip
      ? [...addressObj].filter((x) => x).join(', ') + ' ' + zip
      : [...addressObj].filter((x) => x).join(', ');
  }

  async generateDoc(htmlTemplate: any, fileName: any) {
    await asBlob(htmlTemplate, { orientation: 'portrait' }).then(
      (data: any) => {
        saveAs(data, `${fileName}.docx`);
      }
    );
  }
}
