import {
  Actions,
  MARKET_LEADER_LIST,
  PROGRAM_LIST,
  TRANSACTION_MANAGER_LIST,
  USER_LIST,
  SELECT_PROJECT,
  UPDATE_PHASE_PROJECT,
  RESET_GLOBAL_ACTION,
  PROPERTY_TYPE_LIST,
} from '../actions/global-actions';

export interface GlobalReducerState {
  marketLeaderList: any[];
  transactionManagerList: any[];
  userList: any[];
  programList: any[];
  selectedProject: any;
  propertyTypeList: any[];
}

const initialState: GlobalReducerState = {
  userList: [],
  marketLeaderList: [],
  transactionManagerList: [],
  programList: [],
  selectedProject: null,
  propertyTypeList: [],
};

export function GlobalReducer(
  state = initialState,
  action: any
): GlobalReducerState {
  switch (action.type) {
    case TRANSACTION_MANAGER_LIST: {
      return {
        ...state,
        transactionManagerList: action.payload,
      };
    }
    case MARKET_LEADER_LIST: {
      return { ...state, marketLeaderList: action.payload };
    }
    case PROPERTY_TYPE_LIST: {
      return { ...state, propertyTypeList: action.payload };
    }
    case USER_LIST: {
      return { ...state, userList: action.payload };
    }
    case PROGRAM_LIST: {
      return { ...state, programList: action.payload };
    }
    case SELECT_PROJECT: {
      return { ...state, selectedProject: action.payload };
    }
    case UPDATE_PHASE_PROJECT: {
      return { ...state, selectedProject: action.payload };
    }
    case RESET_GLOBAL_ACTION: {
      return {
        ...state,
        userList: [],
        marketLeaderList: [],
        transactionManagerList: [],
        programList: [],
        selectedProject: null,
        propertyTypeList: [],
      };
    }
    default:
      return state;
  }
}

export const transactionManagerList = (state: GlobalReducerState) =>
  state.transactionManagerList;
export const marketLeaderList = (state: GlobalReducerState) =>
  state.marketLeaderList;
export const userList = (state: GlobalReducerState) => state.userList;
export const programList = (state: GlobalReducerState) => state.programList;
export const propertyTypeList = (state: GlobalReducerState) =>
  state.propertyTypeList;
