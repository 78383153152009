import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PeqCommitmentService {
  constructor(private http: HttpClientService) {}

  generatePECommitmentDocument(document: any) {
    return this.http.documentGet(
      `${APICONSTANTS.DOCUMENT}${APICONSTANTS.PE_COMMITMENT}`,
      document
    );
  }

  getPECommitmentDocumentData(body: any) {
    return this.http
      .post(`${APICONSTANTS.DOCUMENT}${APICONSTANTS.PE_COMMITMENT}/data`, body)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  generatePETermSheetDocument(document: any) {
    return this.http.documentGet(
      `${APICONSTANTS.DOCUMENT}${APICONSTANTS.PE_TERM_SHEET}`,
      document
    );
  }

  getPETermSheetDocumentData(body: any) {
    return this.http
      .post(`${APICONSTANTS.DOCUMENT}${APICONSTANTS.PE_TERM_SHEET}/data`, body)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getPEClientContactName() {
    return this.http
      .get(
        `${APICONSTANTS.DOCUMENT}${APICONSTANTS.PE_TERM_SHEET}/client-contact-name`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
