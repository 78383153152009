import { Component } from '@angular/core';
import { MdbNotificationRef } from 'mdb-angular-ui-kit/notification';

@Component({
  selector: 'app-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.scss']
})
export class ToastrComponent {
  severity!: string;
  icon!: string;
  summary!: string;
  detail!: string;
  notificationRef: MdbNotificationRef<ToastrComponent> | null = null;

  constructor() {}

  ngOnInit(): void {}
}
