export enum RichTextKeys {
  // note that these terms are based on the program table/model columns
  Note = 'notes',
  Term = 'termDefinition',
  BillCollect = 'billingAndCollectionDefinition',
  FlowOfFunds = 'flowOfFunds',
  DefaultForeClosue = 'defaultAndForeclosure',
  EligibleImprove = 'eligibleImprovements',
  SIR = 'sirDefinition',
  Servicing = 'servicingDescription',
  StatusOfEquity = 'statusOfEquity',
  ThirdPartyReviewStatus = 'thirdPartyReviewStatus',
  asset_nextSteps = 'asset_nextSteps',
  asset_impact_eeNotes = 'asset_impact_eeNotes',
  TimelineNotes = 'timelineNotes',
  UnderwrittenDebtCoverage = 'underwrittenDebtCoverage',
  ApprovalProcess = 'approvalProcess',
  ProgramContacts = 'programContacts',
  ServicingNotes = 'servicingNotes',
  EligibleImprovements = 'eligibleImprovements',
  ConcentrationLimits = 'concentrationLimits',
  DelinquencyNotes = 'delinquencyNotes',
  LobbyingActivity = 'lobbyingActivity',
  KeyGoverningDocuments = 'keyGoverningDocuments',
  PartiesToAssessmentContract = 'partiesToAssessmentContract',
  Certification = 'certification',
  Legislation = 'legislation',
  ProgramBarrier = 'programBarrier',
  EaseOfExpansion = 'easeOfExpansion',
  PaymentRemittanceInstruction = 'paymentRemittanceInstruction',

  // UnderwrittenDebtServiceCoverageRatio = 'underwrittenDebtServiceCoverageRatio'
}
