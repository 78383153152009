import { ActionReducer, INIT, UPDATE } from '@ngrx/store';
import { AppState } from './app.state';

export const hydrationMetaReducer = (
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> => {
  return (state, action) => {
    if (action.type === INIT || action.type === UPDATE) {
      const storageValue = localStorage.getItem('app_state');
      if (storageValue) {
        try {
          return JSON.parse(storageValue);
        } catch {
          localStorage.removeItem('app_state');
        }
      }
    }
    const nextState = reducer(state, action);
    localStorage.setItem('app_state', JSON.stringify(nextState));
    return nextState;
  };
};
