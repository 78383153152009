import {Injectable} from '@angular/core';
import {PaceEquityUtils} from './pace-equity.utils';
@Injectable()
export class Sorting {
  constructor(private paceEquityUtils: PaceEquityUtils) {}

  public sort(
    arr: any,
    sortBy: string,
    reverse: boolean = false,
    type: string = 'string',
    specific: string = ''
  ): any {
    switch (type) {
      case 'string':
        arr = arr.sort((a: any, b: any) => {
          const dynamicValueA = this.paceEquityUtils.getDynamicPropertyValue(a, sortBy);
          const dynamicValueB = this.paceEquityUtils.getDynamicPropertyValue(b, sortBy);
          const valueA =
            specific == 'datatape'
              ? this.getValueByType(dynamicValueA?.value, type)
              : this.getValueByType(dynamicValueA, type);
          const valueB =
            specific == 'datatape'
              ? this.getValueByType(dynamicValueB?.value, type)
              : this.getValueByType(dynamicValueB, type);

          if (valueA < valueB) {
            return reverse ? 1 : -1;
          } else if (valueA > valueB) {
            return reverse ? -1 : 1;
          } else {
            return 0;
          }
        });
        break;
      case 'number':
      case 'date':
        arr = arr.sort((a: any, b: any) => {
          const dynamicValueA = this.paceEquityUtils.getDynamicPropertyValue(a, sortBy);
          const dynamicValueB = this.paceEquityUtils.getDynamicPropertyValue(b, sortBy);
          const valueA: any =
            specific == 'datatape'
              ? this.getValueByType(dynamicValueA?.value, type)
              : this.getValueByType(dynamicValueA, type);
          const valueB: any =
            specific == 'datatape'
              ? this.getValueByType(dynamicValueB?.value, type)
              : this.getValueByType(dynamicValueB, type);

          return reverse ? valueB - valueA : valueA - valueB;
        });
        break;
    }
    return arr;
  }

  private getValueByType = (value: any, type: string = 'string') => {
    let castedValue = value;
    switch (type) {
      case 'string':
        castedValue = value ? value.toLowerCase() : '';
        break;
      case 'date':
        castedValue = value && Date.parse(value);
        break;
    }
    return castedValue;
  };
}
