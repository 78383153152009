export const SERVICING_TYPES = {
    ASSESSMENT_BILLING: {
      TAX_PAYMENT: 'Tax Payment',
      SECURED_RECEIPT: 'Secured Receipt',
      BILLING: 'Billing',
      CERTIFICATION: 'Certification',
      PACE_PAYMENT: 'PACE Payment',
    },
  
    DELINQUENCY_CATEGORY: [
      {label: 'Both Categories', value: 'BOTH_CATEGORIES'},
      {label: 'PACE Payment', value: 'PACE Payment'},
      {label: 'Secured Receipt', value: 'Secured Receipt'},
    ],
  };
  
  export const LEVEL_OF_VALIDITY_OPINION_OPTIONS = [
    {
      label: 'Program Level',
      value: 'Program Level',
    },
    {
      label: 'Asset Level',
      value: 'Asset Level',
    },
  ];
  