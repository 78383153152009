<div class="form-group has-search mb-0">
  <span class="form-control-feedback">
    <i class="icofont-search"></i>
  </span>
  <input
    type="text"
    class="form-control"
    id="searchKeyword"
    autocomplete="off"
    placeholder="Search"
    [ngModel]="searchKeyword | async"
    (ngModelChange)="searchKeyword.next($event)"
  />
</div>
