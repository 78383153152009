import { Component } from '@angular/core';
import { PaceEquityUtils } from 'src/app/utils';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  permissionData: any;

  constructor(private _paceEquityUtils: PaceEquityUtils) {}

  ngOnInit() {
    this.permissionData = this._paceEquityUtils.getPermissionAccess();
  }
}
