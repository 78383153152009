import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor(private http: HttpClientService) {}

  saveAssetProperty(asset: any) {
    return this.http
      .post(
        `${APICONSTANTS.PROJECT}/${asset.projectId}${APICONSTANTS.ASSET}/${asset.assetId}${APICONSTANTS.PROPERTY}`,
        asset
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  calculateAssetRate(
    projectId: number,
    assetId: number,
    data: any,
    closeDate: any
  ) {
    return this.http
      .post(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}/pricing/asset-rates?closeDate=${closeDate}`,
        data
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchAssetPricing(projectId: number, assetId: number) {
    return this.http
      .get(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}/pricing`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateAssetProperty(asset: any) {
    return this.http
      .put(
        `${APICONSTANTS.PROJECT}/${asset.projectId}${APICONSTANTS.ASSET}/${asset.assetId}${APICONSTANTS.PROPERTY}`,
        asset
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchTreasuryData() {
    return this.http.get(`${APICONSTANTS.PROJECT}/treasury-data`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateCreditMetrics(asset: any, obj: { assetId: number; projectId: number }) {
    return this.http
      .post(
        `${APICONSTANTS.PROJECT}/${obj.projectId}${APICONSTANTS.ASSET}/${obj.assetId}${APICONSTANTS.ASSET_CREDITMETRICS}`,
        asset
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveCreditMetrics(asset: any, obj: { assetId: number; projectId: number }) {
    return this.http
      .post(
        `${APICONSTANTS.PROJECT}/${obj.projectId}${APICONSTANTS.ASSET}/${obj.assetId}${APICONSTANTS.ASSET_CREDITMETRICS}`,
        asset
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  checkAmoEndBalance(projectId: number, assetId: number, asset: any) {
    return this.http
      .post(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}/${APICONSTANTS.AMO_END_BALANCE}`,
        asset
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  unlinkFolder(assetId: number) {
    return this.http
      .remove(`${APICONSTANTS.ASSET}/${assetId}/unlink-folder`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  generateDocumentTemplate(body: any) {
    return this.http
      .post(`${APICONSTANTS.ASSET}${APICONSTANTS.GENERATE_TEMPLATE}`, body)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchCreditMetrics(projectId: number, assetId: number) {
    return this.http
      .get(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}/credit-metrics`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchImpactDataByAssetId(projectId: number, assetId: number) {
    return this.http
      .get(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}/impacts`
      )
      .pipe(
        map((reponse: any) => {
          return reponse;
        })
      );
  }

  fetchPropertyByAssetId(projectId: number, assetId: number) {
    return this.http
      .get(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}/property`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchAssetFundingSources(projectId: number, assetId: number) {
    return this.http
      .get(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}/funding-sources`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  // return this.http.post(`${APICONSTANTS.PROJECT}/${asset.projectId}${APICONSTANTS.ASSET}/${asset.assetId}${APICONSTANTS.PROPERTY}`, asset).pipe(

  saveImpactNotes(data: any, projectId: number, assetId: number) {
    return this.http
      .post(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}/impacts/notes`,
        data
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  removeImpactNotes(projectId: number, assetId: number, noteId: number) {
    return this.http
      .remove(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}/impacts/notes/${noteId}`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateImpactNotes(
    data: any,
    noteId: number,
    projectId: number,
    assetId: number
  ) {
    return this.http
      .put(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}/impacts/notes/${noteId}`,
        data
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateImpactData(asset: any, projectId: number, assetId: number) {
    return this.http
      .post(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}${APICONSTANTS.ASSET_IMPACT_DATA}`,
        asset
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveImpactData(asset: any, projectId: number, assetId: number) {
    return this.http
      .post(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}${APICONSTANTS.ASSET_IMPACT_DATA}`,
        asset
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveBusinessCase(
    data: any,
    projectId: number,
    assetId: number,
    queryParams = { assessmentId: 0 }
  ) {
    return this.http
      .post(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}${APICONSTANTS.ASSET_IMPACT_DATA}/business-case`,
        data,
        queryParams
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveAssetFundingSource(projectId: number, assetId: number, data: any) {
    return this.http
      .post(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}/funding-sources`,
        data
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateAssetFundingSource(
    projectId: number,
    assetId: number,
    data: any,
    fundingSourceId: number
  ) {
    return this.http
      .put(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}/funding-sources/${fundingSourceId}`,
        data
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteAssetFundingSource(
    projectId: number,
    assetId: number,
    fundingSourceId: number
  ) {
    return this.http
      .remove(
        `${APICONSTANTS.PROJECT}/${projectId}${APICONSTANTS.ASSET}/${assetId}/funding-sources/${fundingSourceId}`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  fetchAssetCapitalStackDebtServiceCalculation(assetId: number) {
    return this.http
      .get(
        `${APICONSTANTS.ASSET}/${assetId}/capital-stack/debt-service-calculation`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
